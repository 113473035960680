import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import {
	API_LOGIN_URL,
	API_CHECK_AUTH_URL,
	API_PLENTY_LOGIN_URL,
	API_ORDERS_URL,
	API_SCHEDULE_URL,
	API_PRINTER_URL,
	API_GCODE_URL,
	API_CLIENT_URL,
} from '../config';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { OrdersContext } from './OrdersContext';
// import { useNavigate } from 'react-router-dom';

const PrintersContext = createContext({
	printers: [],
	err: null,
	schedule: null,
	triggerReschedule: null,
	chartSchedule: null,
	printerStatusUpdated: false,
	isPaused: true,
	isManual: true,
	client: null,
	isLoadingPriners: true,
	setErr: () => {},
	getPrinters: () => {},
	getPrinterStatus: () => {},
	sendItemsForPrint: () => {},
	getSchedule: () => {},
	setPrinterStatusUpdated: () => {},
	setSchedule: () => {},
	setIsPaused: () => {},
	setIsManual: () => {},
	manualPrint: () => {},
});

let dataForManual = {};
let gcodes = {};
let printItemIndexForPrinter = {};
let isChangingTimeline = false;

function PrintersProvider({ children }) {
	const { user, logout } = useContext(AuthContext);
	const [printers, setPrinters] = useState([]);
	const [printerStatusUpdated, setPrinterStatusUpdated] = useState(false);
	const [err, setErr] = useState(null);
	const [schedule, setSchedule] = useState(null);
	const [triggerReschedule, setTriggerReschedule] = useState(0);
	const [chartSchedule, setChartSchedule] = useState(null);
	const [scheduleLoading, setScheduleLoading] = useState(false);

	const [isManual, setIsManual] = useState(true);

	const [isPaused, setIsPaused] = useState(true);
	const [client, setClient] = useState(null);

	const [isLoadingPriners, setIsLoadingPrinters] = useState(true);

	const firstTimeLoading = useRef(true);

	const getClient = async () => {
		const res = await axios.get(API_CLIENT_URL + '/me', {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			console.log('client', res.data.client);
			setClient(res.data.client);
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const getPrinters = async () => {
		setIsLoadingPrinters(true);
		const res = await axios.get(API_PRINTER_URL, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			setPrinters(res.data.printers);
		} else {
			setErr(res.data);
		}
		setIsLoadingPrinters(false);
	};

	const getPrinterStatus = (printer) => {
		return axios
			.get(`${printer.ip_address}/api/job`, {
				headers: {
					'X-Api-Key': printer.api_key,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					res.data['printer'] = printer;
					return res.data;
				}
			})
			.catch((err) => {
				console.log(err);
				return { state: 'Not Operational' };
			});
	};

	const deletePrintingItems = async (printerNames) => {
		try {
			let res = await axios.post(API_SCHEDULE_URL + `/printing/delete`, printerNames, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			});
			console.log('results deleting printing item', Date.now());
			return res;
		} catch (err) {
			return err;
		}
	};

	async function getPrintersStatus() {
		// no need to update printer status if client is without printer
		if (!client || client.withoutPrinter == undefined || client.withoutPrinter) {
			setPrinterStatusUpdated(true);
			return {
				printers: printers,
				completion: {},
			};
		}

		let localPrinters = [...printers];
		console.log('localPrinters', localPrinters);
		let completion = {};

		// Map printers to an array of Promises for getPrinterStatus
		const promises = localPrinters.map((printer) => {
			return getPrinterStatus(printer)
				.then((res) => {
					printer.connection_status = res.state;
					if (res.progress) {
						completion[printer.printer_name] = res.progress.completion;
					}
					return res;
				})
				.catch((err) => {
					console.log(err);
					printer.connection_status = 'Not Operational';
				});
		});

		// Wait for all Promises to resolve before moving on
		const results = await Promise.all(promises);

		// delete printing items for all printers that are done printing
		const deletePrinterNames = results
			.filter((result) => result && result.progress && result.progress.completion === 100.0)
			.map((result) => result.printer.printer_name);

		await deletePrintingItems({ printerNames: deletePrinterNames });

		setPrinterStatusUpdated(true);
		setPrinters(localPrinters);
		return {
			printers: localPrinters,
			completion: completion,
		};
	}

	const sendItemsForPrint = async ({
		changeInFailedPrinters,
		printItemIndexForPrinter,
		printersInHarvestState,
		printers,
		completion,
		scheduleLocal,
	}) => {
		console.log(
			'in sendItemsForPrint',
			changeInFailedPrinters,
			printItemIndexForPrinter,
			printersInHarvestState,
			printers,
			completion,
			scheduleLocal
		);
		console.log('scheduleLocal state', scheduleLocal);
		console.log('step 4.0 - updating compelition');
		updateChartScheduleFromSchedule(scheduleLocal, completion);

		let printingPrinters = printers.filter((printer) => printer.connection_status === 'Printing');
		// update end time for all printers in printing state
		while (printingPrinters.length) {
			let selectedPrinter = printingPrinters.pop();
			let printer_name = selectedPrinter.printer_name;
			let index = printItemIndexForPrinter[printer_name] - 1; //-1 because printItemIndexForPrinter represents item which will be printed next
			let item = scheduleLocal[printer_name][index];
			console.log(
				'last step 1 - calling updateTimeLinePrinting',
				'scheduleLocal',
				scheduleLocal,
				'printer_name',
				printer_name,
				'index',
				index,
				'item',
				item,
				'completion',
				completion
			);
			await updateTimeLinePrinting(scheduleLocal, printer_name, index, item, completion);
			console.log(
				'last step 9 - calling updateTimeLinePrinting',
				'scheduleLocal',
				scheduleLocal,
				'printer_name',
				printer_name,
				'index',
				index,
				'item',
				item,
				'completion',
				completion
			);
		}

		if (!isPaused) {
			console.log(
				'step 2 - sendItemsForPrint ',
				'changeInFailedPrinters',
				changeInFailedPrinters,
				'printItemIndexForPrinter',
				printItemIndexForPrinter,
				'harvest_time',
				client.harvest_time
			);
			if (changeInFailedPrinters) {
				console.log('step 2.1.1 - updating schedule', scheduleLocal);
				let newSchedule = await getSchedule();

				for (const key of Object.keys(scheduleLocal)) {
					delete scheduleLocal[key];
				}

				for (const [key, value] of Object.entries(newSchedule)) {
					scheduleLocal[key] = value;
				}

				setSchedule(newSchedule);

				updatePrintItemIndexForPrinter(printItemIndexForPrinter, printers);

				console.log('step 2.1.2 - updated schedule', scheduleLocal);
			}

			await sendItemsForPrintHelper({
				scheduleLocal,
				printItemIndexForPrinter,
				printersInHarvestState,
				printers,
				completion,
			});
			console.log(
				'step 2.9 - sendItemsForPrint ',
				'changeInFailedPrinters',
				changeInFailedPrinters,
				'printItemIndexForPrinter',
				printItemIndexForPrinter,
				'harvest_time',
				client.harvest_time
			);
		}
	};

	const sendItemsForPrintHelper = async ({
		scheduleLocal,
		printItemIndexForPrinter,
		printersInHarvestState,
		printers,
		completion,
	}) => {
		console.log(
			'step 3 - sendItemsForPrintHelper ',
			'printersInHarvestState',
			printersInHarvestState,
			'printItemIndexForPrinter',
			printItemIndexForPrinter,
			'harvest_time',
			client.harvest_time
		);
		let operationalPrinters = printers.filter(
			(printer) => printer.connection_status === 'Operational' && !printersInHarvestState.has(printer.printer_name)
		);
		// sort operationalPritners by name => start sending print commands from top to bottom
		operationalPrinters.sort((a, b) => (a.printer_name > b.printer_name ? 1 : -1));

		while (operationalPrinters.length) {
			// pop a printer
			let selectedPrinter = operationalPrinters.pop();
			let printer_name = selectedPrinter.printer_name;

			if (printItemIndexForPrinter[printer_name] === undefined) {
				printItemIndexForPrinter[printer_name] = 0;
			}
			if (
				!scheduleLocal ||
				!scheduleLocal[printer_name] ||
				scheduleLocal[printer_name].length === 0 ||
				(printItemIndexForPrinter[printer_name] !== undefined &&
					printItemIndexForPrinter[printer_name] >= scheduleLocal[selectedPrinter.printer_name].length)
			) {
				continue;
			}

			let item = scheduleLocal[printer_name][printItemIndexForPrinter[printer_name]];

			console.log(
				'step 3.1 - sendItemsForPrintHelper ',
				'selectedPrinter',
				selectedPrinter,
				'printer_name',
				printer_name,
				'item',
				item,
				'printItemIndexForPrinter[printer_name]',
				printItemIndexForPrinter[printer_name]
			);
			console.log(printItemIndexForPrinter[printer_name] === 0 ? 0 : parseFloat(client.harvest_time) * 60 * 1000);

			printersInHarvestState.add(printer_name);

			let waitingPeriod = 0;

			if (!isManual) {
				waitingPeriod = printItemIndexForPrinter[printer_name] === 0 ? 0 : parseFloat(client.harvest_time) * 60 * 1000;
			}

			await updateTimeLineNewPrint(
				scheduleLocal,
				printer_name,
				printItemIndexForPrinter[printer_name],
				item,
				waitingPeriod,
				completion
			);

			if (!isManual) {
				new Promise((resolve) =>
					setTimeout(
						resolve,
						// if first item then no delay, else delay for harvest time
						waitingPeriod
					)
				).then(async () => {
					let res = await sendItemToPrinter(item, selectedPrinter);
					if (res.status != 201) {
						printersInHarvestState.delete(printer_name);
						throw new Error('Printer not operational');
					}
					printItemIndexForPrinter[printer_name] += 1;
					printersInHarvestState.delete(printer_name);
				});
			} else {
				let res = await sendItemToPrinter(item, selectedPrinter);
				if (res.status != 201) {
					printersInHarvestState.delete(printer_name);
					throw new Error('Printer not operational');
				}
				printItemIndexForPrinter[printer_name] += 1;
				printersInHarvestState.delete(printer_name);
			}
		}
		console.log(
			'step 3.9 - sendItemsForPrintHelper ',
			'scheduleLocal',
			scheduleLocal,
			'printItemIndexForPrinter',
			printItemIndexForPrinter,
			'harvest_time',
			client.harvest_time
		);
	};

	const updatePrintItemIndexForPrinter = (printItemIndexForPrinter, printers) => {
		let printersInPrintingState = printers.filter((printer) => printer.connection_status === 'Printing');
		// set printItemIndexForPrinter to 1 for all printers in printing state
		for (let i = 0; i < printersInPrintingState.length; i++) {
			const printer = printersInPrintingState[i];
			printItemIndexForPrinter[printer.printer_name] = 1;
			console.log('setting printItemIndexForPrinter to 1 for printing printers', printItemIndexForPrinter);
		}
	};

	const updateTimeLineNewPrint = async (scheduleLocal, printer_name, index, item, waitingPeriod, completion) => {
		let idealStart = new Date(item.start);
		let actualStart = new Date().setTime(new Date().getTime() + waitingPeriod);
		let gap = actualStart - idealStart;

		if (index !== 0 && !isManual) {
			scheduleLocal[printer_name][index - 1].end = new Date().toISOString();
		}

		for (let i = index; i < scheduleLocal[printer_name].length; i++) {
			let itemStart = new Date(scheduleLocal[printer_name][i].start);
			let itemEnd = new Date(scheduleLocal[printer_name][i].end);

			itemStart.setTime(itemStart.getTime() + gap);

			itemEnd.setTime(itemEnd.getTime() + gap);
			scheduleLocal[printer_name][i].start = itemStart.toISOString();
			scheduleLocal[printer_name][i].end = itemEnd.toISOString();
		}
		console.log('manual print test step 4.0 - updatedTimeLine');
		updateChartScheduleFromSchedule(scheduleLocal, completion);
	};

	const updateTimeLinePrinting = async (scheduleLocal, printer_name, index, item, completion) => {
		console.log('updatingtimeLine', scheduleLocal, printer_name, index, item, completion);
		console.log(
			'manual print test last step 2 - inside updateTimeLinePrinting',
			scheduleLocal,
			printer_name,
			index,
			item,
			completion
		);
		let idealEnd = new Date(item.end);
		let actualEnd = new Date();

		if (idealEnd.getTime() > actualEnd.getTime()) {
			console.log('last step 8 - no need to update', 'idealEnd', idealEnd, 'actualEnd', actualEnd);
			return;
		}

		console.log('last step 2.1 - need to update', 'idealEnd', idealEnd, 'actualEnd', actualEnd);
		let gap = actualEnd - idealEnd;

		console.log(
			'last step 3 - updating current index',
			'scheduleLocal[printer_name][index]',
			scheduleLocal[printer_name][index]
		);
		scheduleLocal[printer_name][index].end = actualEnd.toISOString();
		console.log(
			'last step 3.1 - updated current index',
			'scheduleLocal[printer_name][index]',
			scheduleLocal[printer_name][index]
		);

		console.log('last step 4 - updating scheduleLocal', 'scheduleLocal', scheduleLocal);
		for (let i = index + 1; i < scheduleLocal[printer_name].length; i++) {
			let itemStart = new Date(scheduleLocal[printer_name][i].start);
			let itemEnd = new Date(scheduleLocal[printer_name][i].end);

			itemStart.setTime(itemStart.getTime() + gap);

			itemEnd.setTime(itemEnd.getTime() + gap);
			scheduleLocal[printer_name][i].start = itemStart.toISOString();
			scheduleLocal[printer_name][i].end = itemEnd.toISOString();
		}
		console.log('last step 4.1 - updated scheduleLocal', 'scheduleLocal', scheduleLocal);

		console.log('step 4.0 - updatedTimeLine');
		updateChartScheduleFromSchedule(scheduleLocal, completion);
	};

	const sendItemToPrinter = async (item, selectedPrinter) => {
		// download gcode from s3
		let res = await downloadGcodeFromS3(item);
		if (res.status != 200) {
			throw new Error('Gcode not found');
		}

		const formData = new FormData();
		formData.append('file', res.data, `${item.item_name}.gcode`);
		formData.append('print', true);

		// send gcode to printer
		res = await sendGcodeToPrinter(selectedPrinter, formData, `${item.item_name}.gcode`);

		if (res.status != 201) {
			throw new Error('Gcode Sent failed');
		}

		await addItemInPrintingTable(item, selectedPrinter);

		return res;
	};

	const addItemInPrintingTable = async (item) => {
		let res = await axios.post(
			API_SCHEDULE_URL + '/printing',
			{ item: item },
			{
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			}
		);

		return res;
	};

	const downloadGcodeFromS3 = async (item) => {
		// if item in gcodes then return
		if (gcodes[item.item_name]) {
			return gcodes[item.item_name];
		}

		let res = await axios.get(API_GCODE_URL + `/file/${item.item_name}`, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});

		gcodes[item.item_name] = res;
		return res;
	};

	const sendGcodeToPrinter = async (printer, formData, filename) => {
		let res = await axios.post(`${printer.ip_address}/api/files/local`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'X-Api-Key': printer.api_key,
			},
			data: {
				command: 'select',
				print: true,
			},
		});
		return res;
	};

	const getSchedule = async () => {
		setScheduleLoading(true);
		printItemIndexForPrinter = {};

		if (printers.length === 0) {
			await getPrinters();
			// wait for printers status to load
		}

		await getPrintersStatus();

		try {
			// API_SCHEDULE_URL
			const res = await axios.post(
				API_SCHEDULE_URL,
				{ printers: printers },
				{
					headers: {
						Authorization: `Bearer ${user.access_token}`,
					},
				}
			);

			if (res.status === 200) {
				let schedule = res.data.schedule;
				setScheduleLoading(false);
				console.log('step 4.0 - fetched latest schedule');
				updateChartScheduleFromSchedule(schedule, {});

				// get all gcode files for this schedule
				await getAllGcodes(schedule);

				return schedule;
			} else {
				console.log('error');

				setScheduleLoading(false);
				return null;
			}
		} catch (error) {
			console.log(error);

			setScheduleLoading(false);
			return null;
		}
	};

	const getAllGcodes = async (schedule) => {
		let items = new Set();
		Object.values(schedule).forEach((printer) => {
			printer.forEach((item) => {
				items.add(item.item_name);
			});
		});

		let itemsArray = Array.from(items);

		// all will be downloaded in parallel bcz of forEach and async
		itemsArray.forEach(async (item_name) => {
			await downloadGcodeFromS3({ item_name: item_name });
		});
	};

	const updateChartScheduleFromSchedule = (scheduleLocal, completion) => {
		console.log('step 4.1 - updating chartScheduleLocal', 'scheduleLocal', scheduleLocal);

		let chartScheduleLocal = [].concat(...Object.values(scheduleLocal));

		if (!chartScheduleLocal || chartScheduleLocal.length === 0) {
			setChartSchedule([]);
			return;
		}

		console.log('completion', completion);

		chartScheduleLocal = chartScheduleLocal.map((item) => {
			return [
				`${completion[item.printer_name] ? `(${parseFloat(completion[item.printer_name]).toFixed(2)}%)` : ''} ${
					item.printer_name
				}`,
				item.item_name,
				`<style>.google-visualization-tooltip {white-space: nowrap; padding:10px;}</style><div class="tooltip"><span class ="tooltiptext"><p > <span style="font-weight:bold">Item:</span> ${
					item.item_name
				} </p><p> <span style="font-weight:bold">Start Time:</span> ${new Date(
					item.start
				).toLocaleTimeString()} </p><p> <span style="font-weight:bold">End Time:</span> ${new Date(
					item.end
				).toLocaleTimeString()} </p></span></div>`,
				item.color,
				new Date(item.start),
				new Date(item.end),
				item.client_id,
				item.order_id, // order id
			];
		});
		setChartSchedule(chartScheduleLocal);
		console.log('step 4.1 - updated chartScheduleLocal', 'scheduleLocal', scheduleLocal);
	};

	const updateChartScheduleFromCompletion = (completion) => {
		if (!chartSchedule || chartSchedule.length === 0) {
			return;
		}

		console.log('completion', completion);

		let chartScheduleLocal = chartSchedule.map((item) => {
			// trim item[0] to )
			if (item[0].indexOf('%)') > -1) {
				item[0] = item[0].substring(item[0].indexOf('%)') + 2).trim();
			}

			return [
				`${completion[item[0]] ? `(${parseFloat(completion[item[0]]).toFixed(2)}%)` : ''} ${item[0]}`,
				item[1],
				`<style>.google-visualization-tooltip {white-space: nowrap; padding:10px;}</style><div class="tooltip"><span class ="tooltiptext"><p > <span style="font-weight:bold">Item:</span> ${
					item[1]
				} </p><p> <span style="font-weight:bold">Start Time:</span> ${new Date(
					item[4]
				).toLocaleTimeString()} </p><p> <span style="font-weight:bold">End Time:</span> ${new Date(
					item[5]
				).toLocaleTimeString()} </p></span></div>`,
				item[3],
				new Date(item[4]),
				new Date(item[5]),
				item[6],
				item[7], // order id
			];
		});

		console.log('chartScheduleLocal', chartScheduleLocal);
		setChartSchedule(chartScheduleLocal);
	};

	useEffect(() => {
		if (!printers.length || printerStatusUpdated) {
			// setPrinterStatusUpdated(false);
			return;
		}
		getPrintersStatus();
	}, [printers]);

	const handlePrinterStateChange = (
		prevFailedPrintersObj,
		printItemIndexForPrinter,
		printersInHarvestState,
		printers,
		completion,
		scheduleLocal
	) => {
		let failedPrintersLocal = printers
			.filter((printer) => printer.connection_status !== 'Operational' && printer.connection_status !== 'Printing')
			.map((printer) => printer.printer_name);
		failedPrintersLocal.sort();

		console.log(
			'failedPrintersLocal',
			failedPrintersLocal.toString(),
			'prevFailedPrintersObj.prevFailedPrinters',
			prevFailedPrintersObj.prevFailedPrinters.toString()
		);

		if (failedPrintersLocal.toString() !== prevFailedPrintersObj.prevFailedPrinters.toString()) {
			prevFailedPrintersObj.prevFailedPrinters = failedPrintersLocal;
			console.log('prevFailedPrintersObj.prevFailedPrinters', prevFailedPrintersObj.prevFailedPrinters.toString());

			// remove failed printers from printItemIndexForPrinter
			// iterate on failedPrintersLocal and remove all items from printItemIndexForPrinter
			failedPrintersLocal.forEach((printer_name) => {
				delete printItemIndexForPrinter[printer_name];
			});

			sendItemsForPrint({
				changeInFailedPrinters: true,
				printItemIndexForPrinter: printItemIndexForPrinter,
				printersInHarvestState: printersInHarvestState,
				printers: printers,
				completion: completion,
				scheduleLocal: scheduleLocal,
			});
		} else {
			sendItemsForPrint({
				changeInFailedPrinters: false,
				printItemIndexForPrinter: printItemIndexForPrinter,
				printersInHarvestState: printersInHarvestState,
				printers: printers,
				completion: completion,
				scheduleLocal: scheduleLocal,
			});
		}
	};

	// for automatic mode
	useEffect(() => {
		if (!user || !client || isManual || client.withoutPrinter) return;

		console.log('step 1 - isPaused', isPaused);

		let prevFailedPrintersObj = {
			prevFailedPrinters: [],
		};
		// let printItemIndexForPrinter = {};
		let printersInHarvestState = new Set();
		let scheduleLocal = schedule;
		(async () => {
			if (!isPaused) {
				scheduleLocal = await getSchedule();
				setSchedule(scheduleLocal);
			}
		})();

		(async () => {
			let { printers, completion } = await getPrintersStatus();
			// this is running for the first time only, so we need to update the printItemIndexForPrinter for printers in printing state
			// dont update if isPaused
			if (!isPaused || firstTimeLoading.current) {
				updatePrintItemIndexForPrinter(printItemIndexForPrinter, printers);
				firstTimeLoading.current = false;
			}
			handlePrinterStateChange(
				prevFailedPrintersObj,
				printItemIndexForPrinter,
				printersInHarvestState,
				printers,
				completion,
				scheduleLocal
			);
			console.log(
				'prevFailedPrintersObj.prevFailedPrinters after call',
				prevFailedPrintersObj.prevFailedPrinters.toString()
			);
		})();

		const intervalId = setInterval(async () => {
			let { printers, completion } = await getPrintersStatus();
			handlePrinterStateChange(
				prevFailedPrintersObj,
				printItemIndexForPrinter,
				printersInHarvestState,
				printers,
				completion,
				scheduleLocal
			);
			console.log(
				'prevFailedPrintersObj.prevFailedPrinters after call',
				prevFailedPrintersObj.prevFailedPrinters.toString()
			);
		}, 1000 * 10);

		return () => clearInterval(intervalId);

		// clear the interval on component unmount
	}, [isPaused, triggerReschedule, isManual]);

	useEffect(() => {
		if (!user || !client || !isManual) return;

		// alert("Manual Mode is working")
		// console.log("updated localPrinters", printers)

		console.log('step 1 - isPaused', isPaused);

		let prevFailedPrintersObj = {
			prevFailedPrinters: [],
		};
		// let printItemIndexForPrinter = {};
		let printersInHarvestState = new Set();
		let scheduleLocal = schedule;

		let intervalId;
		(async () => {
			scheduleLocal = await getSchedule();
			setSchedule(scheduleLocal);

			let { printers, completion } = await getPrintersStatus();
			// if printer in printing state and printItemIndexForPrinter is not updated, update it
			// check if printer is in printing state
			let printingPrinters = printers.filter((printer) => printer.connection_status === 'Printing');
			// check if printItemIndexForPrinter is empty
			if (Object.keys(printItemIndexForPrinter).length === 0) {
				updatePrintItemIndexForPrinter(printItemIndexForPrinter, printers);
			}

			updateChartScheduleFromSchedule(scheduleLocal, completion);
			// this is running for the first time only, so we need to update the printItemIndexForPrinter for printers in printing state
			// dont update if isPaused

			// update end time for all printers in printing state
			while (printingPrinters.length) {
				let selectedPrinter = printingPrinters.pop();
				let printer_name = selectedPrinter.printer_name;
				let index = printItemIndexForPrinter[printer_name] - 1; //-1 because printItemIndexForPrinter represents item which will be printed next
				let item = scheduleLocal[printer_name][index];

				await updateTimeLinePrinting(scheduleLocal, printer_name, index, item, completion);
			}
			dataForManual = {
				scheduleLocal,
				printItemIndexForPrinter,
				printersInHarvestState,
				printers,
				completion,
			};

			console.log('dataForManual', dataForManual);

			intervalId = setInterval(async () => {
				if (!isChangingTimeline) {
					isChangingTimeline = true;
					console.log('isChangingTimeline useEffect IN', isChangingTimeline);
					let { printers, completion } = await getPrintersStatus();
					updateChartScheduleFromSchedule(scheduleLocal, completion);
					let printingPrinters = printers.filter((printer) => printer.connection_status === 'Printing');
					// update end time for all printers in printing state
					while (printingPrinters.length) {
						let selectedPrinter = printingPrinters.pop();
						let printer_name = selectedPrinter.printer_name;
						let index = printItemIndexForPrinter[printer_name] - 1; //-1 because printItemIndexForPrinter represents item which will be printed next
						let item = scheduleLocal[printer_name][index];
						await updateTimeLinePrinting(scheduleLocal, printer_name, index, item, completion);
					}

					dataForManual = {
						scheduleLocal,
						printersInHarvestState,
						printers,
						completion,
					};
					console.log('dataForManual useEffect printItemIndexForPrinter', printItemIndexForPrinter);

					console.log('dataForManual', dataForManual);

					isChangingTimeline = false;
					console.log('isChangingTimeline useEffect OUT', isChangingTimeline);
				}
			}, 1000 * 10);
		})();
		return () => clearInterval(intervalId);
		// clear the interval on component unmount
	}, [isManual, triggerReschedule]);

	const manualPrint = async () => {
		if (!client.withoutPrinter) {
			// wait if isChangingTimeline is true till it becomes false
			while (isChangingTimeline) {
				await new Promise((r) => setTimeout(r, 500));
			}

			isChangingTimeline = true;
			console.log('isChangingTimeline manualprint IN', isChangingTimeline);
			await sendItemsForPrintHelper({
				...dataForManual,
				printItemIndexForPrinter,
			});
			await getPrintersStatus();
			isChangingTimeline = false;
			console.log('isChangingTimeline manualprint OUT', isChangingTimeline);
		}
	};

	useEffect(() => {
		if (!user) return;
		fetchData();
	}, [user]);

	const fetchData = async () => {
		await getClient();
		getPrinters();
	};

	return (
		<PrintersContext.Provider
			value={{
				printers,
				err,
				isPaused,
				client,
				schedule,
				triggerReschedule,
				isManual,
				setSchedule,
				scheduleLoading,
				printerStatusUpdated,
				setPrinterStatusUpdated,
				setErr,
				getPrinters,
				getPrinterStatus,
				sendItemsForPrint,
				getSchedule,
				chartSchedule,
				setIsPaused,
				setTriggerReschedule,
				setIsManual,
				manualPrint,
				isLoadingPriners,
			}}
		>
			{children}
		</PrintersContext.Provider>
	);
}

export { PrintersContext, PrintersProvider };
