import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import CommonMenu from '../Components/CommonMenu';
import { API_CLIENT_URL } from '../config';
import { AuthContext } from '../Contexts/AuthContext';
import { OrdersContext } from '../Contexts/OrdersContext';
import Select from 'react-select';
import SideBar from '../Components/SideBar';
import { newCustomStyles } from '../Components/NewTableStyles';
import DataTable from 'react-data-table-component';
import { Check, NotePencil, Trash, X } from '@phosphor-icons/react';
import CustomButton from '../Components/CustomButton';
import toast from 'react-hot-toast';

function ErpDetails() {
	const [showModal, setShowModal] = React.useState(false);
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const [loading, setLoading] = useState(true);
	const [canNotChange, setCanNotChange] = useState(true);

	const { getOrders } = useContext(OrdersContext);

	const [formattedErpSystem, setFormattedErpSystem] = useState([]);
	const [columns, setColumns] = useState([]);

	const defaultClient = {
		name: '',
		email: '',
		address: '',
		telefon: '',
		pricing_category: '',
		availabilty: '',
		erp_username: '',
		erp_password: '',
		erp_host: '',
		erp_apikey: '',
		harvest_time: '',
		buffer: 0,
	};

	const keysClientCanChange = [
		'erp_username',
		'erp_password',
		'erp_host',
		'erp_apikey',
		'availabilty',
		'harvest_time',
		// "buffer",
	];

	const [err, setErr] = useState(null);
	const [client, setClient] = useState(defaultClient);
	const [updatedClient, setUpdatedClient] = useState(defaultClient);
	const [optionsErpSystem, setOptionsErpSystem] = useState([
		{ value: 'plenty', label: 'plenty' },
		{ value: 'manual', label: 'manual' },
	]);

	const { user } = useContext(AuthContext);

	const getClient = async () => {
		const res = await axios.get(API_CLIENT_URL + '/me', {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			setClient(res.data.client);
			setUpdatedClient(res.data.client);
		} else {
			setErr(res.data);
		}
	};

	const updateClient = async (e) => {
		e.preventDefault();

		const toastId = toast.loading('Updating Erp System...');

		// change buffer to float
		updatedClient.buffer = parseFloat(updatedClient.buffer);

		await axios
			.put(API_CLIENT_URL + `/${updatedClient.id}`, updatedClient, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((res) => {
				if (res.status === 201) {
					toast.success('Erp System Updated!', { id: toastId });
					getOrders({ noCache: 1 });
					getClient({});
					setErr(null);
					setShowModal(false);
				} else {
					toast.error('Error Updating Erp System!', { id: toastId });
					setErr(String(res.data));
				}
			})
			.catch((err) => {
				toast.error('Error Updating Erp System!', { id: toastId });
				setErr(err.response.data.message);
			});
	};

	const init = async () => {
		console.log('called init', user);
		setLoading(true);
		console.log('user', user);
		if (!user) return;
		await getClient();

		setLoading(false);
	};

	useEffect(() => {
		if (!loading && client) {
			setCanNotChange(keysClientCanChange.some((key) => client[key] !== null && client[key] !== ''));

			let formattedErpSystemLocal = [
				{
					erp_system: client.erp_system,
					erp_username: client.erp_username,
					erp_password: client.erp_password,
					erp_host: client.erp_host,
					erp_apikey: client.erp_apikey,
					availabilty: client.availabilty,
					harvest_time: client.harvest_time,
					buffer: client.buffer,
					action: (
						<div className=" p-2 flex gap-6">
							{/* <img src="../assets/img/delete.svg" alt="" className='w-6' onClick={() => { setDeleteGcodeName(gcode.gcode_name); setDeleteGcodeClientId(gcode.client_id); setShowDeleteModal(true) }} /> */}

							<button
								onClick={() => {
									setUpdatedClient(client);
									setShowModal(true);
								}}
							>
								<NotePencil size={20} />
							</button>
						</div>
					),
				},
			];
			setFormattedErpSystem(formattedErpSystemLocal);

			let columnsLocal = [
				{
					name: 'ERP System',
					selector: 'erp_system',
					reorder: true,
				},
				{
					name: 'ERP Username',
					selector: 'erp_username',
					reorder: true,
				},
				{
					name: 'ERP Password',
					selector: 'erp_password',
					reorder: true,
				},
				{
					name: 'ERP Host',
					selector: 'erp_host',
					reorder: true,
				},
				{
					name: 'ERP API Key',
					selector: 'erp_apikey',
					reorder: true,
				},
				{
					name: 'Availabilty',
					selector: 'availabilty',
					reorder: true,
				},
				{
					name: 'Harvest Time (min)',
					selector: 'harvest_time',
					reorder: true,
				},
				{
					name: 'Buffer (%)',
					selector: 'buffer',
					reorder: true,
				},
				{
					name: 'Action',
					selector: 'action',
					reorder: true,
				},
			];
			setColumns(columnsLocal);
		}
	}, [loading, client]);

	useEffect(() => {
		init();
	}, [user]);

	useEffect(() => {
		console.log('ritik ', client);
		console.log('ritik updated', updatedClient);
	}, [client, updatedClient]);

	return (
		<div className="flex w-full">
			<SideBar active_page={'erp'} />

			{loading ? (
				<div className="flex justify-center items-center h-screen flex-1 ">
					<p className="text-5xl font-bold text-gray-400">Loading...</p>
				</div>
			) : (
				<div className="py-10 px-4 flex-1 h-screen overflow-auto">
					<div className="text-3xl mb-2 text-center">ERP Management</div>
					<div className="bg-white relative overflow-auto px-5 py-5 mt-5">
						{canNotChange ? (
							<DataTable
								columns={columns}
								data={formattedErpSystem}
								customStyles={newCustomStyles}
								fixedHeader
								fixedHeaderScrollHeight="60vh"
							/>
						) : (
							<>
								<div className="grid grid-cols-9">
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">ERP System</div>
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">ERP User Name</div>
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">ERP Password</div>
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">ERP Host</div>
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">ERP API Key</div>
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">Availabilty</div>
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">Harvest Time (min)</div>
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">Buffer (%)</div>
									<div className="border border-slate-300 p-2 font-semibold overflow-auto">Action</div>
								</div>
								<form onSubmit={updateClient}>
									<div className="grid grid-cols-9">
										<Select
											name="UserProfile"
											id="UserProfile"
											className="border border-slate-300 p-2"
											required
											value={optionsErpSystem.value}
											onChange={(e) => {
												setUpdatedClient({
													...updatedClient,
													id: updatedClient.id,
													erp_system: e.value,
												});
											}}
											options={optionsErpSystem}
										></Select>

										<input
											required
											type="text"
											className="border border-slate-300 p-2 overflow-auto"
											value={updatedClient.erp_username}
											onChange={(e) => {
												setUpdatedClient({
													...updatedClient,
													erp_username: e.target.value,
												});
											}}
											disabled={updatedClient['erp_apikey'] || updatedClient['erp_system'] == 'manual' ? true : false}
										/>
										<input
											required
											type="text"
											className="border border-slate-300 p-2 overflow-auto"
											value={updatedClient.erp_password}
											onChange={(e) => {
												setUpdatedClient({
													...updatedClient,
													erp_password: e.target.value,
												});
											}}
											disabled={updatedClient['erp_apikey'] || updatedClient['erp_system'] == 'manual' ? true : false}
										/>
										<input
											required
											type="text"
											className="border border-slate-300 p-2 overflow-auto"
											value={updatedClient.erp_host}
											onChange={(e) => {
												setUpdatedClient({
													...updatedClient,
													erp_host: e.target.value,
												});
											}}
											disabled={updatedClient['erp_apikey'] || updatedClient['erp_system'] == 'manual' ? true : false}
										/>
										<input
											required
											type="text"
											className="border border-slate-300 p-2 overflow-auto"
											value={updatedClient.erp_apikey}
											onChange={(e) => {
												setUpdatedClient({
													...updatedClient,
													erp_apikey: e.target.value,
												});
											}}
											disabled={
												updatedClient['erp_username'] ||
												updatedClient['erp_password'] ||
												updatedClient['erp_host'] ||
												updatedClient['erp_system'] == 'manual'
													? true
													: false
											}
										/>
										<input
											required
											type="text"
											className="border border-slate-300 p-2 overflow-auto"
											value={updatedClient.availabilty}
											onChange={(e) => {
												setUpdatedClient({
													...updatedClient,
													availabilty: e.target.value,
												});
											}}
											disabled={updatedClient['erp_system'] == 'manual' ? true : false}
										/>
										<input
											required
											type="text"
											className="border border-slate-300 p-2 overflow-auto"
											value={updatedClient.harvest_time}
											onChange={(e) => {
												setUpdatedClient({
													...updatedClient,
													harvest_time: e.target.value,
												});
											}}
										/>
										<input
											required
											type="number"
											className="border border-slate-300 p-2 overflow-auto"
											value={updatedClient.buffer}
											onChange={(e) => {
												setUpdatedClient({
													...updatedClient,
													buffer: e.target.value,
												});
											}}
										/>
										<div className="border border-slate-300 p-2"> </div>
										{err && <div className="text-red-500">{err}</div>}
									</div>

									<br />
									<div>
										<button className="border border-slate-300 rounded px-4 py-1 bg-slate-700	text-white" type="submit">
											Submit
										</button>
									</div>
								</form>
							</>
						)}
					</div>

					{/* update modal */}
					{showModal ? (
						<>
							<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
								<div className="relative mx-auto w-[50vw] max-w-3xl">
									{/*content*/}
									<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
										{/*header*/}
										<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
											<h3 className="text-3xl font-semibold">Update ERP</h3>
											<button
												className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
												onClick={() => setShowModal(false)}
											>
												<X size={22} />
											</button>
										</div>
										{/*body*/}
										<div className="relative py-6 flex-auto">
											<form onSubmit={updateClient} className="h-1/2 flex flex-col justify-between">
												<div className="grid grid-cols-1 px-6">
													{client['erp_system'] == 'plenty' && (
														<>
															<div className="flex justify-between gap-10">
																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		ERP Username
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="ERP Username"
																		name="erp_username"
																		value={updatedClient['erp_username']}
																		onChange={(e) =>
																			setUpdatedClient({
																				...updatedClient,
																				erp_username: e.target.value,
																			})
																		}
																		disabled={updatedClient['erp_apikey'] ? true : false}
																	/>
																</div>
																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		ERP Password
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="ERP Password"
																		name="erp_password"
																		value={updatedClient['erp_password']}
																		onChange={(e) =>
																			setUpdatedClient({
																				...updatedClient,
																				erp_password: e.target.value,
																			})
																		}
																		disabled={updatedClient['erp_apikey'] ? true : false}
																	/>
																</div>
															</div>
															<div className="flex justify-between gap-10">
																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		ERP Host
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="ERP Host"
																		name="erp_host"
																		value={updatedClient['erp_host']}
																		onChange={(e) =>
																			setUpdatedClient({
																				...updatedClient,
																				erp_host: e.target.value,
																			})
																		}
																		disabled={updatedClient['erp_apikey'] ? true : false}
																	/>
																</div>
																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		ERP APIKEY
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="ERP APIKEY"
																		name="erp_apikey"
																		value={updatedClient['erp_apikey']}
																		onChange={(e) =>
																			setUpdatedClient({
																				...updatedClient,
																				erp_apikey: e.target.value,
																			})
																		}
																		disabled={
																			updatedClient['erp_username'] ||
																			updatedClient['erp_password'] ||
																			updatedClient['erp_host']
																				? true
																				: false
																		}
																	/>
																</div>
															</div>
															<div className="flex justify-center gap-10">
																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		Availabilty
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="Availabilty"
																		name="availabilty"
																		value={updatedClient['availabilty']}
																		onChange={(e) =>
																			setUpdatedClient({
																				...updatedClient,
																				availabilty: e.target.value,
																			})
																		}
																	/>
																</div>
															</div>
														</>
													)}
													<div className="flex justify-between gap-10">
														<div className="flex flex-col w-1/2">
															<label className="text-slate-700">
																Harvest Time (min)
																<span className="text-red-500 after:content-['*']"></span>
															</label>
															<input
																className="border border-slate-300 p-2"
																required
																type="text"
																placeholder="Harvest Time"
																name="harvest_time"
																value={updatedClient['harvest_time']}
																onChange={(e) =>
																	setUpdatedClient({
																		...updatedClient,
																		harvest_time: e.target.value,
																	})
																}
															/>
														</div>
														<div className="flex flex-col w-1/2">
															<label className="text-slate-700">
																Buffer (%)
																<span className="text-red-500 after:content-['*']"></span>
															</label>
															<input
																className="border border-slate-300 p-2"
																required
																type="number"
																placeholder="Buffer"
																name="buffer"
																value={updatedClient['buffer']}
																onChange={(e) =>
																	setUpdatedClient({
																		...updatedClient,
																		buffer: e.target.value,
																	})
																}
															/>
														</div>
													</div>
													{err && <div className="text-red-500">{err}</div>}
												</div>
												<br />
												<div className="flex justify-between border-t py-4 px-6">
													<CustomButton
														icon={<Trash color="#f44336" size={20} />}
														text={'Discard'}
														onClickFunction={(e) => {
															e.preventDefault();
															setShowModal(false);
														}}
														colorClass={'red-500'}
													/>
													<CustomButton
														icon={<Check color="#01A66C" size={20} />}
														text={'Save Changes'}
														colorClass={'green-500'}
													/>
													{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white' type="submit">Submit</button>
                                                <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white' onClick={() => { setShowModal(false) }}>Cancel</button> */}
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
						</>
					) : null}
					{/* update modal */}
				</div>
			)}
		</div>
	);
}

export default ErpDetails;
