import axios from 'axios';
import React, { useContext, useState } from 'react';
import CommonMenu from '../Components/CommonMenu';
import { API_CLIENT_USER_URL } from '../config';
import { AuthContext } from '../Contexts/AuthContext';
import SideBar from '../Components/SideBar';

function UpdatePassword() {
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');
	const [err, setErr] = useState(null);

	const { user } = useContext(AuthContext);

	const updatePassword = async (e) => {
		e.preventDefault();

		if (password !== confirmPassword) {
			setPassword('');
			setConfirmPassword('');
			setErr('Passwords do not match');
			setMessage(null);
			return;
		}

		const res = await axios.put(
			API_CLIENT_USER_URL + '/me',
			{ password: password },
			{
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			}
		);

		if (res.status === 200) {
			console.log(res.data);
			setPassword('');
			setConfirmPassword('');
			setMessage('Password updated successfully');
			setErr(null);
		} else {
			console.log(res.data);
			setMessage(null);
			setErr(res.data);
		}
	};

	return (
		<div className="flex w-full">
			<SideBar active_page={'update-password'} />
			<div className="h-screen flex justify-center items-center flex-col flex-1">
				<h1>Update Password</h1>
				<form onSubmit={updatePassword} className="flex flex-col mt-4">
					<input
						className="border border-slate-300 p-2 rounded-xl"
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<input
						className="border border-slate-300 p-2 mt-2 rounded-xl"
						type="password"
						placeholder="Confirm Password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					/>
					<button className="border border-slate-300 px-4 py-1 bg-slate-700 text-white mt-2 rounded-xl" type="submit">
						Update Password
					</button>
				</form>
				{message && <div className="text-green-500">{message}</div>}
				{err && <div className="text-red-500">{err}</div>}
			</div>
		</div>
	);
}

export default UpdatePassword;
