import React, { createContext, useState, useEffect, useContext } from 'react';
import { API_LOGIN_URL, API_CHECK_AUTH_URL, API_PLENTY_LOGIN_URL, API_ORDERS_URL, API_SCHEDULE_URL } from '../config';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import { PrintersContext } from './PrintersContext';
// import { useNavigate } from 'react-router-dom';

const OrdersContext = createContext({
	ordersResponse: {
		orders: [],
	},

	areOrdersLoading: true,
	err: null,
	getOrders: () => {},
});

function OrdersProvider({ children }) {
	const [ordersResponse, setOrdersResponse] = useState({
		orders: [],
	});

	const [areOrdersLoading, setAreOrdersLoading] = useState(true);
	const [err, setErr] = useState(null);

	const { user } = useContext(AuthContext);
	const { printerStatusUpdated, triggerReschedule, setTriggerReschedule } = useContext(PrintersContext);

	const getOrders = async ({ noCache = 0, page = 1 }) => {
		console.log('fetching orders');
		try {
			setAreOrdersLoading(true);
			const res = await axios.get(API_ORDERS_URL + `/gcode/${page}?nocache=${noCache}`, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			});

			if (res.status === 200) {
				setOrdersResponse(res.data);
				console.log('creating schedule 2');
				// let scheduleLocal = await getSchedule();
				// setSchedule(scheduleLocal);
				setTriggerReschedule((triggerReschedule + 1) % 2);
			} else {
				setOrdersResponse({
					orders: [],
				});
			}
			setAreOrdersLoading(false);

			setErr(null);
		} catch (error) {
			console.log(error);
			setAreOrdersLoading(false);

			setErr(error.response.data.message);
		}
	};

	useEffect(() => {
		console.log('setPrinterStatusUpdated', printerStatusUpdated);
		if (!printerStatusUpdated) return;

		// if (printerStatusUpdated) {
		//     setPrinterStatusUpdated(false);
		//     return;
		// }

		getOrders({});
		const intervalId = setInterval(
			() => {
				// update the state every 30 seconds
				getOrders({});
			},
			1000 * 2 * 60 * 60
		);

		// clear the interval on component unmount
		return () => clearInterval(intervalId);
	}, [printerStatusUpdated]);

	useEffect(() => {
		getOrders({});
	}, []);

	return (
		<OrdersContext.Provider value={{ ordersResponse, areOrdersLoading, err, getOrders }}>
			{children}
		</OrdersContext.Provider>
	);
}

export { OrdersContext, OrdersProvider };
