export const API_BASE_URL = process.env.REACT_APP_API_URL;
// export const API_BASE_URL = "http://18.177.81.126/api";
export const API_LOGIN_URL = `${API_BASE_URL}/login`;
export const API_PLENTY_LOGIN_URL = `${API_BASE_URL}/plenty_login`;
export const API_ORDERS_URL = `${API_BASE_URL}/orders`;
export const API_CHECK_AUTH_URL = `${API_BASE_URL}/checkAuth`;
export const API_CLIENT_URL = `${API_BASE_URL}/client`;
export const API_GCODE_URL = `${API_BASE_URL}/gcode`;
export const API_CLIENT_USER_URL = `${API_BASE_URL}/clientUser`;
export const API_PRINTER_URL = `${API_BASE_URL}/printer`;
export const API_PRINTERS_URL = `${API_BASE_URL}/printers`;
export const API_SCHEDULE_URL = `${API_BASE_URL}/schedule`;
