import { Navigate } from 'react-router-dom';
import React, { createContext, useState, useEffect } from 'react';
import { API_LOGIN_URL, API_CHECK_AUTH_URL, API_PLENTY_LOGIN_URL } from '../config';
import axios from 'axios';

const AuthContext = createContext({
	isAuthenticated: false,
	user: null,
	isPlentyAuthenticated: false,
	showMenu: false,
	getToken: () => {},
	setToken: () => {},
	removeToken: () => {},
	login: () => {},
	logout: () => {},
	checkAuth: () => {},
	setShowMenu: () => {},
});

function AuthProvider({ children }) {
	useEffect(() => {
		if (getToken() !== null && !user) {
			checkAuth();
		} else {
			<Navigate replace to="/login" />;
		}
	}, []);

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	const [showMenu, setShowMenu] = useState(false);

	const getToken = () => {
		return sessionStorage.getItem('token') || null;
	};

	const setToken = (token) => {
		sessionStorage.setItem('token', token);
	};

	const removeToken = () => {
		sessionStorage.removeItem('token');
	};

	const login = async (credentials) => {
		try {
			const res = await axios.post(API_LOGIN_URL, credentials);
			setUser(res.data);
			setToken(res?.data?.access_token);
			setIsAuthenticated(true);
			console.log('User logged in');
		} catch (error) {
			throw new Error(error.response.data.message);
		}
	};

	const logout = () => {
		setUser(null);
		removeToken();
		setIsAuthenticated(false);
	};

	const checkAuth = async () => {
		try {
			const res = await axios.get(API_CHECK_AUTH_URL, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem('token')}`,
				},
			});
			setUser(res.data);
			setIsAuthenticated(true);
			console.log('User logged back in');
		} catch (error) {
			throw new Error(error.response.data.message);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				user,
				login,
				logout,
				checkAuth,
				getToken,
				setToken,
				removeToken,
				showMenu,
				setShowMenu,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
