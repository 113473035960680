import React, { useContext, useEffect, useRef, useState } from 'react';
import CommonMenu from '../Components/CommonMenu';
import ReactLoading from 'react-loading';
import { OrdersContext } from '../Contexts/OrdersContext';
import { AuthContext } from '../Contexts/AuthContext';
import { API_CLIENT_URL, API_GCODE_URL, API_ORDERS_URL } from '../config';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { customStyles } from '../Components/TableStyles';
import { newCustomStyles } from '../Components/NewTableStyles';
import SideBar from '../Components/SideBar';
import CustomButton from '../Components/CustomButton';
import { ArrowsClockwise, Check, Cross, NotePencil, Plus, PlusCircle, Trash, X } from '@phosphor-icons/react';
import TooltipsContainer from '../Components/TooltipsContainer';
import { toast } from 'react-hot-toast';
import Select from 'react-select';
function Orders() {
	const defaultOrder = {
		article_name: '',
		article_variationId: '',
		order_date: '',
		due_date: '',
		quantity: '',
		status: '',
		printed_quantity: 0,
	};
	const [currentPage, setCurrentPage] = useState(1);
	const [pages, setPages] = useState([]);
	const [client, setClient] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [order, setOrder] = useState(defaultOrder);
	const [selectedOrder, setSelectedOrder] = useState(defaultOrder);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState(false);

	const { ordersResponse, areOrdersLoading, err, getOrders } = useContext(OrdersContext);
	const { user } = useContext(AuthContext);

	const [formattedOrders, setFormattedOrders] = useState([]);
	const [columns, setColumns] = useState([]);

	const [selectedRows, setSelectedRows] = React.useState(false);
	const [toggledClearRows, setToggleClearRows] = React.useState(false);
	const [errInCreation, setErrInCreation] = useState(null);

	const [gcodes, setGcodes] = useState([]);
	const [gcodesOptions, setGcodesOptions] = useState([]);
	const [erp_system_not_setup, setErp_system_not_setup] = useState(true);

	const getGcodes = async () => {
		const res = await axios.get(API_GCODE_URL, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			setGcodes(res.data['gcodes']);
			const options = res.data['gcodes'].map((gcode) => {
				return {
					value: gcode['gcode_name'],
					label: gcode['gcode_name'],
				};
			});
			setGcodesOptions(options);
			console.log(res.data);
		} else {
			console.log(res.data);
			toast.error('Error in fetching gcodes');
		}
	};

	const handleChange = ({ selectedRows }) => {
		setSelectedRows(selectedRows);
	};

	const handlePageChange = async (page) => {
		if (page > 0 && page <= ordersResponse['totalPages']) {
			setCurrentPage(page);
			getOrders({ page: page });
		}
	};

	const deleteOrder = async () => {
		const res = await axios.put(
			API_ORDERS_URL + `/delete/${selectedOrder.client_id}/${selectedOrder.order_id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			}
		);
		if (res.status === 204) {
			console.log('order deleted');
			setSelectedOrder(defaultOrder);
			getOrders({});
			setShowDeleteModal(false);
		}
	};

	const updateOrder = async (e) => {
		e.preventDefault();

		const toastId = toast.loading('Updating Order...');

		const res = await axios.put(
			API_ORDERS_URL + `/${selectedOrder.client_id}/${selectedOrder.order_id}`,
			selectedOrder,
			{
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			}
		);
		if (res.status === 204) {
			console.log('order updated');
			toast.success('Order Updated Successfully', { id: toastId });
			setSelectedOrder(defaultOrder);
			getOrders({});
			setShowUpdateModal(false);
		} else {
			toast.error('Error in updating order', { id: toastId });
			setErrInCreation('Error in updating order');
		}
	};

	// a function to delete multiple orders, it wont will wait for all the orders to be deleted, using Promise.all it will use selectedRows array to delete all the orders
	const deleteMultipleOrders = async () => {
		if (selectedRows.length <= 0) {
			toast.error('Please select at least one order to delete');
			return;
		}

		const toastId = toast.loading('Deleting Orders...');

		const promises = selectedRows.map(async (row) => {
			const res = await axios.put(
				API_ORDERS_URL + `/delete/${row.client_id}/${row.order_id}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${user.access_token}`,
					},
				}
			);
			if (res.status === 204) {
				console.log('order deleted');
			}
		});
		await Promise.all(promises);

		toast.success('Orders Deleted Successfully', { id: toastId });

		getOrders({});
		setToggleClearRows(!toggledClearRows);
        setSelectedRows([]);
	};

	const getClient = async () => {
		const res = await axios.get(API_CLIENT_URL + '/me', {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			console.log('client', res.data.client);
			setClient(res.data.client);
		}
	};

	// Function to format a number to two digits
	function twoDigits(n) {
		return n < 10 ? '0' + n : '' + n;
	}

	const createOrder = async (e) => {
		e.preventDefault();

		const toastId = toast.loading('Creating Order...');

		// set order_date to current date in this format: 2023-05-05T03:20
		let date = new Date();

		// Extract parts and build string
		let formattedDate =
			date.getFullYear() +
			'-' +
			twoDigits(date.getMonth() + 1) +
			'-' + // Months are 0-based in JavaScript
			twoDigits(date.getDate()) +
			'T' +
			twoDigits(date.getHours()) +
			':' +
			twoDigits(date.getMinutes());

		order['order_date'] = formattedDate;

		const res = await axios.post(API_ORDERS_URL, order, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});

		if (res.status === 201) {
			console.log('order', res.data);
			toast.success('Order Created Successfully', { id: toastId });
			setOrder(defaultOrder);
			getOrders({});
			setShowCreateModal(false);
			setErrInCreation(null);
		} else {
			toast.error('Error in creating order', { id: toastId });
			setErrInCreation('Error in creating order');
		}
	};

	const init = async () => {
		if (!areOrdersLoading && client) {
			const maxPages = Math.min(5, ordersResponse['totalPages']);
			const startOffset = currentPage - 2 > 0 ? currentPage - 2 : 1;
			const numbers = Array.from({ length: maxPages }, (_, index) => index + startOffset);
			setPages(numbers);

			const columnsLocal = [
				// {
				//     name: 'Client Name',
				//     selector: 'client_name',
				// },
				{
					name: 'Article Name',
					selector: 'article_name',
					sortable: true,
					wrap: true,
					cell: (row) => <div className="font-bold">{row.article_name}</div>,
					reorder: true,
				},
				// {
				//     name: 'Article VariationId',
				//     selector: 'article_variationId',
				//     sortable: true,
				// },
				{
					name: 'Order Date',
					selector: 'order_date',
					sortable: true,
					wrap: true,
					reorder: true,
				},
				{
					name: 'Due Date',
					selector: 'due_date',
					sortable: true,
					wrap: true,
					reorder: true,
				},
				{
					name: 'Quantity',
					selector: 'quantity',
					sortable: true,
					reorder: true,
				},
				{
					name: 'Printed Quantity',
					selector: 'printed_quantity',
					sortable: true,
					reorder: true,
				},
				{
					name: 'Status',
					selector: 'status',
					sortable: true,
					reorder: true,
				},
				{
					name: 'Gcode available',
					selector: 'have_gcode',
					sortable: true,
					cell: (row) => (
						<div
							className={`w-3/4 text-center py-2 rounded  ${
								row.have_gcode === 'Yes' ? 'bg-[#D0F5E9] text-[#01A66C]' : 'bg-[#FFE0E7] text-[#CE3B54]'
							}`}
						>
							{row.have_gcode}
						</div>
					),
					reorder: true,
					omit: true,
				},
			];

			const formattedOrdersLocal = ordersResponse['orders'].map((order) => {
				const formattedOrder = {
					client_name: order.client_name,
					article_name: order.article_name,
					article_variationId: order.article_variationId,
					order_date: order.order_date,
					due_date: order.due_date,
					quantity: order.quantity,
					printed_quantity: order.printed_quantity,
					status: order.status,
					have_gcode: order.gcode != null ? 'Yes' : 'No',
					order_id: order.order_id,
					client_id: order.client_id,
				};

				if (client.erp_system === 'manual') {
					formattedOrder['action'] = (
						<div className="flex gap-4">
							<button
								onClick={() => {
									setSelectedOrder(order);
									setShowUpdateModal(true);
								}}
							>
								<NotePencil size={20} />
							</button>
						</div>
					);
				}

				return formattedOrder;
			});

			if (client.erp_system === 'manual') {
				columnsLocal.push({
					name: 'Actions',
					selector: 'action',
					reorder: true,
				});
			}

			setColumns(columnsLocal);
			setFormattedOrders(formattedOrdersLocal);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		init();
	}, [areOrdersLoading, client]);

	useEffect(() => {
		console.log('user', user);
		if (!user) return;
		getGcodes();
	}, [user]);

	useEffect(() => {
		getClient();
	}, []);

	useEffect(() => {
		if (client) {
			if (client.erp_system == '') {
				setErp_system_not_setup(true);
			} else {
				setErp_system_not_setup(false);
			}
		}
	}, [client]);

	return (
		<div className="flex w-full">
			<SideBar active_page={'orders'} />
			{isLoading ? (
				<div className="flex justify-center items-center h-screen flex-1">
					<p className="text-5xl font-bold text-gray-400">Loading...</p>
				</div>
			) : erp_system_not_setup ? (
				<div className="flex justify-center items-center h-screen flex-1">
					<p className="text-5xl font-bold text-red-400">Setup ERP First</p>
				</div>
			) : (
				<div className="h-screen overflow-auto flex-1 py-10 px-10">
					<div className="relative">
						<div className="flex gap-4 items-center justify-between mb-4">
							{err ? (
								<button className="w-1/3 bg-red-400 text-white p-2 rounded mb-5">{err}</button>
							) : (
								<div className="w-1/3 flex items-center gap-4">
									<>
										{client.erp_system === 'manual' && (
											<CustomButton
												icon={<PlusCircle size={20} />}
												text={'Create Order'}
												onClickFunction={() => {
													setShowCreateModal(true);
												}}
											/>
										)}
									</>
									<CustomButton
										icon={<ArrowsClockwise size={20} />}
										text={'Refresh'}
										onClickFunction={async () => {
											setIsLoading(true);
											await getOrders({ noCache: 1 });
											setIsLoading(false);
										}}
									/>
								</div>
							)}

							<div className="w-1/3 text-3xl mb-2 text-center">Orders Overview</div>

							<div className="w-1/3 flex justify-end">
								<CustomButton
									icon={<Trash color="#f44336" size={20} />}
									text={'Delete'}
									onClickFunction={() => {
										setShowDeleteModal(true);
									}}
									colorClass={'red-500'}
								/>
							</div>
						</div>

						{
							<DataTable
								data={formattedOrders}
								columns={columns}
								customStyles={newCustomStyles}
								fixedHeader
								fixedHeaderScrollHeight="75vh"
								selectableRows={true}
								onSelectedRowsChange={handleChange}
								clearSelectedRows={toggledClearRows}
							/>
						}

						<div className="flex mt-5 justify-center gap-2">
							<button
								className="px-4 py-2 border rounded-full hover:text-white hover:bg-gray-800"
								onClick={() => handlePageChange(currentPage - 1)}
							>
								&#60;
							</button>
							{pages.map((page, key) => (
								<button
									className={
										'px-4 py-2 rounded-full border' +
										(page === currentPage ? ' text-white bg-gray-800' : '') +
										' hover:text-white hover:bg-gray-800'
									}
									key={key}
									onClick={() => handlePageChange(page)}
								>
									{page}
								</button>
							))}
							<button
								className="px-4 py-2 border rounded-full hover:text-white hover:bg-gray-800"
								onClick={() => handlePageChange(currentPage + 1)}
							>
								&#62;
							</button>
						</div>
					</div>

					{showDeleteModal ? (
						<>
							<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
								<div className="relative w-auto my-6 mx-auto max-w-3xl">
									{/*content*/}
									<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
										{/*body*/}
										<div className="relative p-6 flex-auto ">
											<div className="f-22">Are You Sure, you want to delete {selectedOrder['article_name']}?</div>
											<div className="flex justify-between mt-10">
												<button
													className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
													onClick={() => {
														deleteMultipleOrders();
														setShowDeleteModal(false);
													}}
												>
													Yes
												</button>
												<button
													className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
													onClick={() => {
														setShowDeleteModal(false);
													}}
												>
													No
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
						</>
					) : null}

					{showUpdateModal ? (
						<>
							<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
								<div className="relative mx-auto w-[50vw] max-w-3xl">
									{/*content*/}
									<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
										{/*header*/}
										<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
											<h3 className="text-3xl font-semibold">Update Order</h3>
											<button
												className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
												onClick={() => setShowUpdateModal(false)}
											>
												<X size={22} />
											</button>
										</div>
										{/*body*/}
										<div className="relative py-6 flex-auto">
											<form onSubmit={updateOrder} className="h-1/2 flex flex-col justify-between">
												<div className="grid grid-cols-1 px-6">
													{/* input fields for printer update */}
													<div className="flex justify-between gap-10">
														<div className="flex flex-col w-1/2">
															<label htmlFor="">Due Date</label>
															<input
																className="rounded border border-slate-300 p-2"
																required
																type="datetime-local"
																placeholder="Due Date"
																name="due_date"
																value={selectedOrder['due_date']}
																onChange={(e) =>
																	setSelectedOrder({
																		...selectedOrder,
																		due_date: e.target.value,
																	})
																}
															/>
														</div>
														<div className="flex flex-col w-1/2">
															<label htmlFor="">Quantity</label>
															<input
																className="rounded border border-slate-300 p-2"
																required
																type="number"
																placeholder="Quantity"
																name="quantity"
																value={selectedOrder['quantity']}
																onChange={(e) =>
																	setSelectedOrder({
																		...selectedOrder,
																		quantity: e.target.value,
																	})
																}
															/>
														</div>
													</div>
													<div className="flex justify-between gap-10 mt-4">
														{client.withoutPrinter && (
															<div className="flex flex-col w-1/2">
																<label htmlFor="">Printed Quantity</label>
																<input
																	className="border border-slate-300 p-2"
																	required
																	type="number"
																	placeholder="Printed Quantity"
																	name="printed_quantity"
																	value={selectedOrder['printed_quantity']}
																	onChange={(e) =>
																		setSelectedOrder({
																			...selectedOrder,
																			printed_quantity: e.target.value,
																		})
																	}
																/>
															</div>
														)}
														<div className="flex flex-col w-1/2">
															<label htmlFor="">Status</label>
															<input
																className="border border-slate-300 p-2"
																type="text"
																placeholder="Status"
																name="status"
																value={selectedOrder['status']}
																onChange={(e) =>
																	setSelectedOrder({
																		...selectedOrder,
																		status: e.target.value,
																	})
																}
															/>
														</div>
													</div>

													{err && <div className="text-red-500">{err}</div>}
												</div>
												<br />
												<div className="flex justify-between border-t px-6 py-4">
													<CustomButton
														icon={<Trash color="#f44336" size={20} />}
														text={'Discard'}
														onClickFunction={(e) => {
															e.preventDefault();
															setShowUpdateModal(false);
														}}
														colorClass={'red-500'}
													/>
													<CustomButton
														icon={<Check color="#01A66C" size={20} />}
														text={'Save Changes'}
														colorClass={'green-500'}
													/>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
						</>
					) : null}

					{showCreateModal && client.erp_system === 'manual' ? (
						<>
							<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
								<div className="relative mx-auto w-[50vw] max-w-3xl">
									{/*content*/}
									<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
										{/*header*/}
										<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
											<h3 className="text-2xl font-semibold">Create Order</h3>
											<button
												className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
												onClick={() => setShowCreateModal(false)}
											>
												<X size={22} />
											</button>
										</div>
										{/*body*/}
										<div className="relative py-6 flex-auto">
											<form onSubmit={createOrder} className="h-1/2 flex flex-col justify-between">
												<div className="grid grid-cols-1 px-6">
													{/* input fields for printer update */}
													<div className="flex justify-between gap-10">
														<div className="flex flex-col w-1/2">
															<label htmlFor="">
																Article Name <span className="text-red-500 after:content-['*']"></span>
																<span className="text-gray-300"> (Should be same as Gcode)</span>
															</label>
															{/* <input className='rounded border border-slate-300 p-2' required type="text" placeholder='Article Name should be same as Gcode' name="ArticleName" value={order['article_name']} onChange={(e) => setOrder({ ...order, "article_name": e.target.value })} /> */}
															<Select
																options={gcodesOptions}
																onChange={(e) => setOrder({ ...order, article_name: e.value })}
																value={gcodesOptions.filter((option) => option.value === order['article_name'])}
															/>
														</div>

														<div className="flex flex-col w-1/2">
															<label htmlFor="">
																Due Date <span className="text-red-500 after:content-['*']"></span>
															</label>
															<input
																className="rounded border border-slate-300 p-2"
																required
																type="datetime-local"
																placeholder="Due Date"
																name="DueDate"
																value={order['due_date']}
																onChange={(e) =>
																	setOrder({
																		...order,
																		due_date: e.target.value,
																	})
																}
															/>
														</div>
													</div>
													<div className="flex justify-between gap-10 mt-4">
														<div className="flex flex-col w-1/2">
															<label htmlFor="">
																Quantity <span className="text-red-500 after:content-['*']"></span>
															</label>
															<input
																className="rounded border border-slate-300 p-2"
																required
																type="number"
																placeholder="Quantity"
																name="Quantity"
																value={order['quantity']}
																onChange={(e) =>
																	setOrder({
																		...order,
																		quantity: e.target.value,
																	})
																}
															/>
														</div>
														<div className="flex flex-col w-1/2">
															<label htmlFor="">Status</label>
															<input
																className="rounded border border-slate-300 p-2"
																type="text"
																placeholder="Status"
																name="Status"
																value={order['status']}
																onChange={(e) => setOrder({ ...order, status: e.target.value })}
															/>
														</div>
													</div>

													{errInCreation && <div className="text-red-500">{errInCreation}</div>}
												</div>
												<br />
												<div className="flex justify-between border-t px-6 py-4">
													<CustomButton
														icon={<Trash color="#f44336" size={20} />}
														text={'Discard'}
														onClickFunction={(e) => {
															e.preventDefault();
															setShowCreateModal(false);
														}}
														colorClass={'red-500'}
													/>
													<CustomButton
														icon={<Check color="#01A66C" size={20} />}
														text={'Save Changes'}
														colorClass={'green-500'}
													/>
													{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700	text-white' type="submit">Submit</button> */}
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
						</>
					) : null}
				</div>
			)}

			<div>
				<TooltipsContainer />
			</div>
		</div>
	);
}

export default Orders;
