import React from 'react';
import CommonMenu from '../Components/CommonMenu';

function Page404() {
	return (
		<>
			<CommonMenu />
			<div>Page404</div>
		</>
	);
}

export default Page404;
