export const customStyles = {
	rows: {
		style: {
			minHeight: '72px', // override the row height
		},
	},
	headCells: {
		style: {
			paddingLeft: '8px', // override the cell padding for head cells
			paddingRight: '8px',
			fontWeight: 'bold',
			fontSize: '16px',
			color: '#000000',
			border: '1px solid rgb(203 213 225)',
		},
	},
	cells: {
		style: {
			paddingLeft: '8px', // override the cell padding for data cells
			paddingRight: '8px',
			fontSize: '14px',
			color: '#000000',
			border: '1px solid rgb(203 213 225)',
		},
	},
};
