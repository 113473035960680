import React, { useState, useContext, useEffect } from 'react';
import CommonMenu from '../Components/CommonMenu';
import axios from 'axios';
import { AuthContext } from '../Contexts/AuthContext';
import { API_CLIENT_URL, API_CLIENT_USER_URL } from '../config';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AddClients from '../Components/AddClients';
import AddClientUser from '../Components/AddClientUser';

const Dashboard = () => {
	const [showModal, setShowModal] = React.useState(false);

	const defaultClient = {
		name: '',
		email: '',
		address: '',
		telefon: '',
		pricing_category: '',
		availabilty: '',
		erp_username: '',
		erp_password: '',
		erp_host: '',
		erp_apikey: '',
		harvest_time: '',
		withoutPrinter: false,
	};

	const [clients, setClients] = useState([]);
	const [err, setErr] = useState(null);
	const [client, setClient] = useState(defaultClient);
	const [updatedClient, setUpdatedClient] = useState(defaultClient);

	const defaultClientUser = {
		client_id: '',
		client_name: '',
		user_profile: '',
		user_email: '',
		user_password: '',
		shift_time: '',
	};

	const [clientUsers, setClientUsers] = useState([]);
	const [clientUser, setClientUser] = useState(defaultClientUser);
	const [updatedClientUser, setUpdatedClientUser] = useState(defaultClientUser);

	const { user } = useContext(AuthContext);

	const createClient = async (e) => {
		e.preventDefault();
		console.log(user);

		await axios
			.post(API_CLIENT_URL, client, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((res) => {
				if (res.status === 201) {
					console.log(res.data);
					setClient(defaultClient);
					getClients();
					getClientUsers();
					setErr(null);
				} else {
					console.log(res.data);
					setErr(String(res.data));
				}
			})
			.catch((err) => {
				setErr(err.response.data.message);
			});
	};

	const getClients = async () => {
		const res = await axios.get(API_CLIENT_URL, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			setClients(res.data['clients']);
			console.log(res.data);
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const deleteClient = async (id, email, name) => {
		const res = await axios.delete(API_CLIENT_URL + `/${id}/${email}/${name}`, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 204) {
			getClients();
			getClientUsers();
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const updateClient = async (e) => {
		e.preventDefault();
		console.log(user);

		await axios
			.put(API_CLIENT_URL + `/${updatedClient.id}`, updatedClient, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((res) => {
				if (res.status === 201) {
					console.log(res.data);
					getClients();
					getClientUsers();
					setErr(null);
					setShowModal(false);
				} else {
					console.log(res.data);
					setErr(String(res.data));
				}
			})
			.catch((err) => {
				setErr(err.response.data.message);
			});
	};

	const createClientUser = async (e) => {
		e.preventDefault();
		console.log(user);

		await axios
			.post(API_CLIENT_USER_URL, clientUser, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((res) => {
				if (res.status === 201) {
					console.log(res.data);
					setClientUser(defaultClientUser);
					getClientUsers();
					setErr(null);
				} else {
					console.log(res.data);
					setErr(String(res.data));
				}
			})
			.catch((err) => {
				setErr(err.response.data.message);
			});
	};

	const getClientUsers = async () => {
		const res = await axios.get(API_CLIENT_USER_URL, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			setClientUsers(res.data['clientUsers']);
			console.log(res.data);
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const deleteClientUser = async (client_id, email) => {
		const res = await axios.delete(API_CLIENT_USER_URL + `/${client_id}/${email}`, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 204) {
			getClientUsers();
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const updateClientUser = async (e) => {
		e.preventDefault();
		console.log(user);

		await axios
			.put(API_CLIENT_USER_URL + `/${updatedClientUser.client_id}/${updatedClientUser.user_email}`, updatedClientUser, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((res) => {
				if (res.status === 201) {
					console.log(res.data);
					getClientUsers();
					setErr(null);
					setShowModal(false);
				} else {
					console.log(res.data);
					setErr(String(res.data));
				}
			})
			.catch((err) => {
				setErr(err.response.data.message);
			});
	};

	useEffect(() => {
		console.log('user', user);
		if (!user) return;
		getClients();
		getClientUsers();
	}, [user]);

	return (
		<>
			<CommonMenu />

			<div className="mt-16 px-4">
				<Tabs>
					<TabList>
						<Tab>Clients</Tab>
						<Tab>Users</Tab>
					</TabList>

					<TabPanel>
						<AddClients
							clients={clients}
							client={client}
							setClient={setClient}
							createClient={createClient}
							err={err}
							updateClient={updateClient}
							deleteClient={deleteClient}
							updatedClient={updatedClient}
							setUpdatedClient={setUpdatedClient}
							showModal={showModal}
							setShowModal={setShowModal}
						/>
					</TabPanel>
					<TabPanel>
						<AddClientUser
							clients={clients}
							clientUsers={clientUsers}
							clientUser={clientUser}
							setClientUser={setClientUser}
							createClientUser={createClientUser}
							err={err}
							updateClientUser={updateClientUser}
							deleteClientUser={deleteClientUser}
							updatedClientUser={updatedClientUser}
							setUpdatedClientUser={setUpdatedClientUser}
							showModal={showModal}
							setShowModal={setShowModal}
						/>
					</TabPanel>
				</Tabs>
			</div>
		</>
	);
};

export default Dashboard;
