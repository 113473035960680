import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import AddClientUser from '../Components/AddClientUser';
import CommonMenu from '../Components/CommonMenu';
import { API_CLIENT_URL, API_CLIENT_USER_URL } from '../config';
import { AuthContext } from '../Contexts/AuthContext';
import SideBar from '../Components/SideBar';
import TooltipsContainer from '../Components/TooltipsContainer';
import toast from 'react-hot-toast';

function Users() {
	const defaultClientUser = {
		client_id: '',
		client_name: '',
		user_profile: '',
		user_email: '',
		user_password: '',
		shift_time: '',
	};

	const [showModal, setShowModal] = React.useState(false);
	const [clientUsers, setClientUsers] = useState([]);
	const [clientUser, setClientUser] = useState(defaultClientUser);
	const [updatedClientUser, setUpdatedClientUser] = useState(defaultClientUser);
	const [client, setClient] = useState();

	const { user } = useContext(AuthContext);

	const [err, setErr] = useState(null);

	const getClient = async () => {
		const res = await axios.get(API_CLIENT_URL + '/me', {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			console.log('client', res.data.client);
			setClient(res.data.client);
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const createClientUser = async (e) => {
		e.preventDefault();
		console.log(user);

		await axios
			.post(`${API_CLIENT_USER_URL}/${client.id}`, clientUser, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((res) => {
				if (res.status === 201) {
					console.log(res.data);
					setClientUser(defaultClientUser);
					getClientUsers();
					setErr(null);
				} else {
					console.log(res.data);
					setErr(String(res.data));
				}
			})
			.catch((err) => {
				setErr(err.response.data.message);
			});
	};

	const getClientUsers = async () => {
		console.log('client users', 'here');

		const res = await axios.get(`${API_CLIENT_USER_URL}/${client.id}`, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			setClientUsers(res.data['clientUsers']);
			console.log(res.data);
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const deleteClientUser = async (client_id, email) => {
		try {
			const res = await axios.delete(API_CLIENT_USER_URL + `/${client_id}/${email}`, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			});
			if (res.status === 204) {
				getClientUsers();
			}
		} catch (err) {
			console.log(err);
			setErr(err.response.data.message);
		}
	};

	const updateClientUser = async (e) => {
		e.preventDefault();

		const toastId = toast.loading('Updating Client User...');

		await axios
			.put(`${API_CLIENT_USER_URL}/${client.id}`, updatedClientUser, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((res) => {
				if (res.status === 201) {
					toast.success('Client User Updated!', { id: toastId });
					getClientUsers();
					setErr(null);
					setShowModal(false);
				} else {
					toast.error('Error Updating Client User!', { id: toastId });
					setErr(String(res.data));
				}
			})
			.catch((err) => {
				toast.error('Error Updating Client User!', { id: toastId });
				setErr(err.response.data.message);
			});
	};

	useEffect(() => {
		console.log('user', user);
		if (!user) return;
		getClient();
	}, [user]);

	useEffect(() => {
		console.log('client', client);
		if (!client) return;
		getClientUsers();
	}, [client]);

	return (
		<div className="flex w-full">
			<SideBar active_page={'users'} />
			<div className="flex-1">
				{client && clientUsers && (
					<AddClientUser
						clients={[client]}
						clientUsers={clientUsers}
						clientUser={clientUser}
						setClientUser={setClientUser}
						createClientUser={createClientUser}
						err={err}
						updateClientUser={updateClientUser}
						deleteClientUser={deleteClientUser}
						updatedClientUser={updatedClientUser}
						setUpdatedClientUser={setUpdatedClientUser}
						showModal={showModal}
						setShowModal={setShowModal}
						getClientUsers={getClientUsers}
					/>
				)}
			</div>

			<TooltipsContainer />
		</div>
	);
}

export default Users;
