import React, { useState } from 'react';
import Select from 'react-select';

const AddClients = ({
	clients,
	createClient,
	setClient,
	client,
	err,
	deleteClient,
	updateClient,
	updatedClient,
	setUpdatedClient,
	showModal,
	setShowModal,
}) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteClientEmail, setDeleteClientEmail] = useState(null);
	const [deleteClientName, setDeleteClientName] = useState(null);
	const [deleteClientId, setDeleteClientId] = useState(null);

	const [options, setOptions] = useState([
		{ value: false, label: 'No' },
		{ value: true, label: 'Yes' },
	]);

	// also update in backend
	const pricingCategoryLabel = {
		5: '0-5 printers',
		50: '6-50 printers',
		100: '51-100 printers',
		'>100': '>100 printers',
	};

	const [optionsPricingCategory, setPricingCategory] = useState([
		{ value: '5', label: pricingCategoryLabel['5'] },
		{ value: '50', label: pricingCategoryLabel['50'] },
		{ value: '100', label: pricingCategoryLabel['100'] },
		{ value: '>100', label: pricingCategoryLabel['>100'] },
	]);

	return (
		<div className="bg-white relative overflow-auto px-5 py-5 mt-5">
			<div className="grid grid-cols-7">
				<div className="border border-slate-300 p-2 font-semibold overflow-auto">ClientName</div>
				<div className="border border-slate-300 p-2 font-semibold overflow-auto">ClientEmail</div>
				<div className="border border-slate-300 p-2 font-semibold overflow-auto">ClientPhone</div>
				<div className="border border-slate-300 p-2 font-semibold overflow-auto">ClientAddress</div>
				<div className="border border-slate-300 p-2 font-semibold overflow-auto">ClientPricingCategory</div>
				<div className="border border-slate-300 p-2 font-semibold overflow-auto">Without Printer?</div>
				<div className="border border-slate-300 p-2 font-semibold overflow-auto">Actions</div>
			</div>

			{clients.map((client) => (
				<div className="grid grid-cols-7">
					<div className="border border-slate-300 p-2 overflow-auto">{client.name}</div>
					<div className="border border-slate-300 p-2 overflow-auto">{client.email}</div>
					<div className="border border-slate-300 p-2 overflow-auto">{client.telefon}</div>
					<div className="border border-slate-300 p-2 overflow-auto">{client.address}</div>
					<div className="border border-slate-300 p-2 overflow-auto">
						{pricingCategoryLabel[client.pricing_category] == undefined
							? client.pricing_category
							: pricingCategoryLabel[client.pricing_category]}
					</div>
					<div className="border border-slate-300 p-2 overflow-auto">{client.withoutPrinter ? 'Yes' : 'No'}</div>
					<div className="border border-slate-300 p-2 flex gap-6">
						<img
							src="../assets/img/delete.svg"
							alt=""
							className="w-6"
							onClick={() => {
								setDeleteClientName(client.name);
								setDeleteClientEmail(client.email);
								setDeleteClientId(client.id);
								setShowDeleteModal(true);
							}}
						/>
						<img
							src="../assets/img/edit.svg"
							alt=""
							className="w-6"
							onClick={() => {
								setUpdatedClient(client);
								setShowModal(true);
							}}
						/>
					</div>
				</div>
			))}

			<form onSubmit={createClient} className="h-1/2 flex flex-col justify-between">
				<div className="grid grid-cols-7">
					<input
						className="border border-slate-300 p-2"
						required
						type="text"
						placeholder="ClientName"
						name="ClientName"
						value={client['name']}
						onChange={(e) => setClient({ ...client, name: e.target.value })}
					/>
					<input
						className="border border-slate-300 p-2"
						required
						type="email"
						placeholder="ClientEmail"
						name="ClientEmail"
						value={client['email']}
						onChange={(e) => setClient({ ...client, email: e.target.value })}
					/>
					<input
						className="border border-slate-300 p-2"
						required
						type="text"
						placeholder="ClientPhone"
						name="ClientPhone"
						value={client['telefon']}
						onChange={(e) => setClient({ ...client, telefon: e.target.value })}
					/>
					<input
						className="border border-slate-300 p-2"
						required
						type="text"
						placeholder="ClientAddress"
						name="ClientAddress"
						value={client['address']}
						onChange={(e) => setClient({ ...client, address: e.target.value })}
					/>

					<Select
						className="border border-slate-300 p-2"
						options={optionsPricingCategory}
						value={optionsPricingCategory.value}
						name="ClientPricingCategory"
						onChange={(e) => {
							setClient({ ...client, pricing_category: e.value });
							console.log(e.value);
						}}
					/>

					<Select
						className="border border-slate-300 p-2"
						options={options}
						value={options.value}
						onChange={(e) => {
							setClient({ ...client, withoutPrinter: e.value });
							console.log(e.value);
						}}
					/>

					<div className="border border-slate-300 p-2"> </div>
					{err && <div className="text-red-500">{err}</div>}
				</div>
				<br />
				<div>
					<button className="border border-slate-300 rounded px-4 py-1 bg-slate-700	text-white" type="submit">
						Submit
					</button>
				</div>
			</form>

			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h3 className="text-3xl font-semibold">Update Client</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setShowModal(false)}
									>
										<span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
											x
										</span>
									</button>
								</div>
								{/*body*/}
								<div className="relative p-6 flex-auto">
									<form onSubmit={updateClient} className="h-1/2 flex flex-col justify-between">
										<div className="grid grid-cols-1">
											<input
												className="border border-slate-300 p-2"
												required
												type="text"
												placeholder="ClientName"
												name="ClientName"
												value={updatedClient['id']}
												hidden
											/>
											<input
												className="border border-slate-300 p-2"
												required
												type="text"
												placeholder="ClientName"
												name="ClientName"
												value={updatedClient['name']}
												disabled
											/>
											<input
												className="border border-slate-300 p-2"
												required
												type="email"
												placeholder="ClientEmail"
												name="ClientEmail"
												value={updatedClient['email']}
												disabled
											/>
											<input
												className="border border-slate-300 p-2"
												required
												type="text"
												placeholder="ClientPhone"
												name="ClientPhone"
												value={updatedClient['telefon']}
												onChange={(e) =>
													setUpdatedClient({
														...updatedClient,
														telefon: e.target.value,
													})
												}
											/>
											<input
												className="border border-slate-300 p-2"
												required
												type="text"
												placeholder="ClientAddress"
												name="ClientAddress"
												value={updatedClient['address']}
												onChange={(e) =>
													setUpdatedClient({
														...updatedClient,
														address: e.target.value,
													})
												}
											/>

											<Select
												className="border border-slate-300 p-2"
												options={optionsPricingCategory}
												value={optionsPricingCategory.value}
												name="ClientPricingCategory"
												onChange={(e) => {
													setUpdatedClient({
														...updatedClient,
														pricing_category: e.value,
													});
												}}
											/>

											<Select
												className="border border-slate-300 p-2"
												options={options}
												value={options.value}
												onChange={(e) =>
													setUpdatedClient({
														...updatedClient,
														withoutPrinter: e.value,
													})
												}
											/>

											{err && <div className="text-red-500">{err}</div>}
										</div>
										<br />
										<div className="flex justify-between">
											<button
												className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
												type="submit"
											>
												Submit
											</button>
											<button
												className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
												onClick={() => {
													setShowModal(false);
												}}
											>
												Cancel
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}

			{showDeleteModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*body*/}
								<div className="relative p-6 flex-auto ">
									<div className="f-22">Are You Sure, you want to delete {deleteClientName}?</div>
									<div className="flex justify-between mt-10">
										<button
											className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
											onClick={() => {
												deleteClient(deleteClientId, deleteClientEmail, deleteClientName);
												setShowDeleteModal(false);
											}}
										>
											Yes
										</button>
										<button
											className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
											onClick={() => {
												setShowDeleteModal(false);
											}}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</div>
	);
};

export default AddClients;
