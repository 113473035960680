import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext';
import axios from 'axios';
import { API_CLIENT_URL } from '../config';

const CommonMenu = () => {
	const [showMenu, isShowMenu] = useState(false);
	const [menu, setMenu] = useState(false);
	const [menu2, setMenu2] = useState(false);
	const { user, logout } = useContext(AuthContext);
	const [client, setClient] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	let navigate = useNavigate();

	const getClient = async () => {
		setIsLoading(true);

		const res = await axios.get(API_CLIENT_URL + '/me', {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			console.log('client', res.data.client);
			setClient(res.data.client);
		} else {
			console.log(res.data);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (user && user.user_level != 'admin') {
			getClient();
		}
	}, [user]);

	return (
		<>
			<nav className={`bg-darkGrey border-gray-200 px-2 dark:bg-gray-900 w-full z-50 top-0 left-0`}>
				<div className="container flex flex-wrap items-center justify-between mx-auto">
					<a href="#" className="flex items-center">
						<img src="../assets/img/samLogoSmall.png" className="h-6 mr-3 sm:h-9" alt="Flowbite Logo" />
						{/* <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> */}
					</a>
					<div className="flex gap-5 items-center md:order-2">
						{/* <div className="relative hidden md:block">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg className="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                <span className="sr-only">Search icon</span>
                            </div>
                            <input type="text" id="search-navbar" className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg" placeholder="Search..." />
                        </div> */}

						<button
							type="button"
							onClick={() => isShowMenu(!showMenu)}
							className="flex items-center gap-1 mr-3 text-sm bg-transparent-800 rounded-full"
							id="user-menu-button"
							aria-expanded="false"
							data-dropdown-toggle="user-dropdown"
							data-dropdown-placement="bottom"
						>
							{/* <span className="sr-only">Open user menu</span> */}
							<img className="w-8 h-8 rounded-full" src="../assets/img/demouser.png" alt="user photo" />
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="12"
								fill="#fff"
								className="bi bi-caret-down-fill"
								viewBox="0 0 16 16"
							>
								{' '}
								<path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />{' '}
							</svg>
						</button>
						<div
							className={`z-50 absolute top-10 right-10 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 ${
								showMenu ? '' : 'hidden'
							}`}
							id="user-dropdown"
						>
							<div className="px-4 py-3">
								{/* <span className="block text-sm text-gray-900 dark:text-white">Bonnie Green</span> */}
								<span className="block text-sm font-medium text-gray-500 truncate dark:text-gray-400">
									{user.email}
								</span>
							</div>
							<ul className="py-2" aria-labelledby="user-menu-button">
								<li>
									<Link
										to="/update-password"
										className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
									>
										Change Credentials
									</Link>
								</li>
								<li>
									<a
										href="#"
										className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
										onClick={() => {
											logout();
											navigate('/');
										}}
									>
										Sign out
									</a>
								</li>
							</ul>
						</div>
						<button
							data-collapse-toggle="mobile-menu-2"
							type="button"
							className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
							aria-controls="mobile-menu-2"
							aria-expanded="false"
						>
							<span className="sr-only">Open main menu</span>
							<svg
								className="w-6 h-6"
								aria-hidden="true"
								fill="currentColor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
									clipRule="evenodd"
								></path>
							</svg>
						</button>
					</div>

					<div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="mobile-menu-2">
						{user.user_level != 'admin' && (
							<ul className="flex flex-col p-4 mt-4 border border-gray-100 bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-darkGrey dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
								<li>
									<Link
										to="/home"
										className="block py-2 pl-3 pr-4 text-white-700 md:bg-transparent md:text-white md:p-0 dark:text-white"
										aria-current="page"
									>
										Home
									</Link>
								</li>
								<li>
									<Link
										to="/orders"
										className="block py-2 pl-3 pr-4 text-white-700 md:bg-transparent md:text-white md:p-0 dark:text-white"
									>
										Order Overview
									</Link>
								</li>
								{
									// Disable Automatic print
									// client && client.withoutPrinter &&

									<li>
										<Link
											to="/schedule?manual=1"
											className="block py-2 pl-3 pr-4 text-white-700 md:bg-transparent md:text-white md:p-0 dark:text-white"
										>
											Schedule Overview
										</Link>
									</li>
								}
								{/* // Disable Automatic print */}
								{/* {
                                    client && !client.withoutPrinter &&
                                    <div className="relative inline-block text-left">
                                        <div>
                                            <button type="button" className="inline-flex text-white" id="menu-button" aria-expanded="true" aria-haspopup="true" onClick={() => setMenu2(!menu2)}>
                                                Schedule Overview
                                                <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                                                </svg>
                                            </button>
                                        </div>

                                        <div className={`absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-2 focus:outline-none ${menu2 ? '' : 'hidden'}`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                            <div className="py-1" role="none">
                                                <>
                                                    <Link to="/schedule?manual=0" className="text-gray-700 block px-4 py-2 text-sme">Schedule and Automatic Print</Link>

                                                    <Link to="/schedule?manual=1" className="text-gray-700 block px-4 py-2 text-sm">Schedule and Manual Print</Link>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                } */}

								<div className="relative inline-block text-left">
									<div>
										<button
											type="button"
											className="inline-flex text-white"
											id="menu-button"
											aria-expanded="true"
											aria-haspopup="true"
											onClick={() => setMenu(!menu)}
										>
											Management
											<svg
												className="-mr-1 h-5 w-5 text-gray-400"
												viewBox="0 0 20 20"
												fill="currentColor"
												aria-hidden="true"
											>
												<path
													fill-rule="evenodd"
													d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
													clip-rule="evenodd"
												/>
											</svg>
										</button>
									</div>

									<div
										className={`absolute right-0 z-10 mt-2 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-2 focus:outline-none ${
											menu ? '' : 'hidden'
										}`}
										role="menu"
										aria-orientation="vertical"
										aria-labelledby="menu-button"
										tabindex="-1"
									>
										<div className="py-1" role="none">
											{/* <Link href="/404" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</Link>
                                            <Link href="/gcodes" className="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Support</Link> */}
											<Link to="/printers" className="text-gray-700 block px-4 py-2 text-sme">
												Printer Management
											</Link>
											<Link to="/gcodes" className="text-gray-700 block px-4 py-2 text-sm">
												Part Management
											</Link>
											{(user.user_level == 'client_admin' || user.user_level == 'client_superAdmin') && (
												<>
													<li>
														<Link to="/erp" className="text-gray-700 block px-4 py-2 text-sm">
															ERP Management
														</Link>
													</li>
													<li>
														<Link to="/users" className="text-gray-700 block px-4 py-2 text-sm">
															User Management
														</Link>
													</li>
												</>
											)}
										</div>
									</div>
								</div>
							</ul>
						)}
					</div>
				</div>
			</nav>
		</>
	);
};

export default CommonMenu;
