import React, { useState, useContext, useEffect, useRef } from 'react';

import axios from 'axios';
import { AuthContext } from '../Contexts/AuthContext';
import { API_PRINTERS_URL, API_PRINTER_URL } from '../config';

import { PrintersContext } from '../Contexts/PrintersContext';
import SideBar from '../Components/SideBar';
import Tableview from './Tableview';

import Select from 'react-select';
import DataTable from 'react-data-table-component';

import { newCustomStyles } from '../Components/NewTableStyles';
import CustomButton from '../Components/CustomButton';
import { Check, NotePencil, PlusCircle, ToggleRight, Trash, X } from '@phosphor-icons/react';
import TooltipsContainer from '../Components/TooltipsContainer';
import { toast } from 'react-hot-toast';

import { v4 as uuidv4 } from 'uuid';

const Printers = () => {
	const [showModal, setShowModal] = useState(false);

	const [errUpdate, setErrUpdate] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [options, setOptions] = useState([]);
	const [materialOptions, setMaterialOptions] = useState([]);
    const [nozzleSizeOptions, setNozzleSizeOptions] = useState([]);

	const [showCreateModal, setShowCreateModal] = useState(false);

	const [formattedPrinters, setFormattedPrinters] = useState([]);
	const [columns, setColumns] = useState([]);

	const [selectedRows, setSelectedRows] = React.useState(false);
	const [toggledClearRows, setToggleClearRows] = React.useState(false);

	const { printers, err, setErr, getPrinters, client, triggerReschedule, setTriggerReschedule, isLoadingPriners } =
		useContext(PrintersContext);

	const [isLoading, setIsLoading] = useState(true);
	const loadingFirstTime = useRef(true);

	const { user } = useContext(AuthContext);

	const defaultPrinter = {
		client_id: '',
		id: '',
		printer_name: '',
		printer_id: '',
		manufacturer: '',
		technology: '',
		material: '',
		color: '',
        nozzleSize: "0,4 mm",
		ip_address: '',
		connection_status: '',
		api_key: '',
	};

	const [printer, setPrinter] = useState(defaultPrinter);
	const [updatedPrinter, setUpdatedPrinter] = useState(defaultPrinter);

	const createPrinter = async (e) => {
		e.preventDefault();

		const toastId = toast.loading('Creating Printer');

		let printerLocal = { ...printer };

		if (client.withoutPrinter) {
			// generate random ip address and api key
			printerLocal = {
				...printerLocal,
				ip_address: uuidv4(),
				api_key: uuidv4(),
			};
			setPrinter(printerLocal);
		}

		await axios
			.post(API_PRINTER_URL, printerLocal, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then(async (res) => {
				if (res.status === 201) {
					setPrinter(defaultPrinter);
					toast.success('Printer Created', { id: toastId });
					await getPrinters();
					setErr(null);
					setErrUpdate(null);
					// setSchedule(await getSchedule());
					setTriggerReschedule((triggerReschedule + 1) % 2);
				} else {
					toast.error('Error Creating Printer', { id: toastId });
					setErr(String(res.data));
				}
			})
			.catch((err) => {
				toast.error('Error Creating Printer', { id: toastId });
				setErr(err.response.data.message);
			});
	};

	const deletePrinter = async (id) => {
		const res = await axios.delete(API_PRINTER_URL + `/${id}`, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 204) {
			await getPrinters();
			// setSchedule(await getSchedule());
			setTriggerReschedule((triggerReschedule + 1) % 2);
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const updatePrinter = async (e) => {
		e.preventDefault();

		const toastId = toast.loading('Updating Printer');

		await axios
			.put(API_PRINTER_URL + `/${updatedPrinter.id}`, updatedPrinter, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then(async (res) => {
				if (res.status === 201) {
					toast.success('Printer Updated', { id: toastId });
					setUpdatedPrinter(defaultPrinter);
					setShowModal(false);
					await getPrinters();
					setTriggerReschedule((triggerReschedule + 1) % 2);
					setErr(null);
					setErrUpdate(null);
				} else {
					toast.error('Error Updating Printer', { id: toastId });
					setErrUpdate(String(res.data));
				}
			})
			.catch((error) => {
				toast.error('Error Updating Printer', { id: toastId });
				setErrUpdate(error.response.data.message);
			});
	};

	const deleteMultiplePrinter = async (printers) => {
		const toastId = toast.loading('Deleting Printers');

		var printer_ids = printers.map((printer) => {
			return printer.id;
		});

		const res = await axios.put(
			API_PRINTERS_URL + `/delete`,
			{
				printer_ids: printer_ids,
			},
			{
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			}
		);
		if (res.status === 201) {
			toast.success('Printers Deleted', { id: toastId });
			await getPrinters();
			setTriggerReschedule((triggerReschedule + 1) % 2);
		} else {
			toast.error('Error Deleting Printers', { id: toastId });
			setErr(res.data);
		}
	};

	const changeConnectionStatusMultiple = async (printers) => {
		if (!client.withoutPrinter) {
			setErr("You can't change the printer status manually");
			return;
		}

		const toastId = toast.loading('Updating Printers Status');
		// create a new array of printers with updated status and ids only
		var newPrintersStatus = printers.map((printer) => {
			return {
				id: printer.id,
				client_id: client.id,
				connection_status: printer.connection_status === 'Operational' ? 'Offline' : 'Operational',
			};
		});

		await axios
			.put(API_PRINTERS_URL + `/update/status`, newPrintersStatus, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then(async (res) => {
				if (res.status === 201) {
					toast.success('Printers Status Updated', { id: toastId });
					setErr(null);
					setErrUpdate(null);
				} else {
					toast.error('Error Updating Printers Status', { id: toastId });
					setErrUpdate(String(res.data));
				}
			})
			.catch((error) => {
				toast.error('Error Updating Printers Status', { id: toastId });
				setErrUpdate(error.response.data.message);
			});
	};

	const handleChange = ({ selectedRows }) => {
		setSelectedRows(selectedRows);
	};

	const technology = ['FFF', 'SLA', 'SLS', 'SLM', 'DLP', 'FJM'];

	const material = ['PLA', 'ABS', 'PETG', 'TPU', 'Nylon', 'ASA', 'PVB', 'HIPS', 'PVA', 'PC', 'PEI', 'PEEK'];

    const nozzleSize = ["0,2 mm","0,4 mm","0,6 mm","0,8 mm","1,0 mm","1,6 mm"]

	const DeleteMultiplePrinter = async () => {
		if (selectedRows.length === 0) {
			toast.error('Please select at least one printer to delete');
			return;
		}

		await deleteMultiplePrinter(selectedRows);
		setToggleClearRows(!toggledClearRows);
        setSelectedRows([]);
	};

	const updateMultipleConnectionStatus = async () => {
		if (selectedRows.length === 0) {
			toast.error('Please select at least one printer to update');
			return;
		}

		// create a list of printer
		await changeConnectionStatusMultiple(selectedRows);

		await getPrinters();
		setToggleClearRows(!toggledClearRows);
        setSelectedRows([]);

		setTriggerReschedule((triggerReschedule + 1) % 2);
	};

	useEffect(() => {
		if (!isLoadingPriners && client) {
			loadingFirstTime.current = false;

			var technologyOptions = [];
			technology.map((tech) => {
				technologyOptions.push({ value: tech, label: tech });
			});
			console.log(technologyOptions);
			setOptions(technologyOptions);

			var materialOptions = [];
			material.map((mat) => {
				materialOptions.push({ value: mat, label: mat });
			});
			setMaterialOptions(materialOptions);

            var nozzleSizeOptions = [];
            nozzleSize.map((noz) => {
                nozzleSizeOptions.push({ value: noz, label: noz });
            })
            setNozzleSizeOptions(nozzleSizeOptions);

			var formattedPrintersLocal = printers.map((printer) => {
				let data = {
					id: printer.id,
					printer_name: printer.printer_name,
					printer_id: printer.printer_id,
					manufacturer: printer.manufacturer,
					technology: printer.technology,
					material: printer.material,
					color: printer.color,
                    nozzleSize: printer.nozzleSize,

					connection_status: printer.connection_status,
					action: (
						<div style={{ display: 'flex', gap: '10px' }}>
							<button
								onClick={() => {
									setUpdatedPrinter(printer);
									setShowModal(true);
								}}
							>
								<NotePencil size={20} />
							</button>
						</div>
					),
				};

				if (!client.withoutPrinter) {
					data = {
						...data,
						ip_address: printer.ip_address,
						api_key: printer.api_key,
					};
				}

				return data;
			});

			let columnsLocal = [
				// {
				//     name: 'Printer Sam ID',
				//     selector: 'id',
				//     sortable: true,
				//     wrap: true
				// },
				{
					name: 'Printer Name',
					selector: 'printer_name',
					sortable: true,
					wrap: true,
					cell: (row) => <div className="font-bold">{row.printer_name}</div>,
					reorder: true,
				},
				{
					name: 'Printer ID',
					selector: 'printer_id',
					sortable: true,
					wrap: true,
					cell: (row) => (
						<div className="w-full h-full flex justify-center items-center border-r">
							<div className="w-3/4 text-center py-2 rounded bg-[#F7F7F7]">{row.printer_id}</div>
						</div>
					),
					reorder: true,
				},
				{
					name: 'Manufacturer',
					selector: 'manufacturer',
					sortable: true,
					wrap: true,
					reorder: true,
				},
				{
					name: 'Technology',
					selector: 'technology',
					sortable: true,
					wrap: true,
					reorder: true,
				},
				{
					name: 'Material',
					selector: 'material',
					sortable: true,
					wrap: true,
					reorder: true,
				},
				{
					name: 'Color',
					selector: 'color',
					sortable: true,
					wrap: true,
					reorder: true,
				},
                {
                    name: 'Nozzle Diameter',
                    selector: 'nozzleSize',
                    sortable: true,
                    wrap: true,
                    reorder: true,
                },
				{
					name: 'Connection Status',
					selector: (row) => row.connection_status,
					sortable: true,
					wrap: true,
					cell: (row) => (
						<div
							className={`w-3/4 text-center py-2 rounded  ${
								row.connection_status === 'Operational' ? 'bg-[#D0F5E9] text-[#01A66C]' : 'bg-[#FFE0E7] text-[#CE3B54]'
							}`}
						>
							{row.connection_status === 'Operational' ? 'Operational' : 'Offline'}
						</div>
					),
					reorder: true,
				},
				{
					name: 'Actions',
					selector: 'action',
					wrap: true,
					reorder: true,
				},
			];

			if (!client.withoutPrinter) {
				columnsLocal = [
					...columnsLocal,
					{
						name: 'IP Address',
						selector: 'ip_address',
						wrap: true,
						reorder: true,
					},
					{
						name: 'API Key',
						selector: 'api_key',
						wrap: true,
						reorder: true,
					},
				];
			}

			setFormattedPrinters(formattedPrintersLocal);
			setColumns(columnsLocal);
			console.log('formattedPrinters', formattedPrintersLocal);
		}
	}, [isLoadingPriners, client]);

	useEffect(() => {
		setIsLoading(loadingFirstTime.current);
	}, [loadingFirstTime.current]);

	return (
		<div className="flex w-full">
			<SideBar active_page={'printers'} />

			<Tableview
				isLoading={isLoading}
				heading={'Printers Management'}
				leftButtons={[
					<CustomButton
						icon={<PlusCircle size={20} />}
						text={'Add Printer'}
						onClickFunction={() => {
							setShowCreateModal(true);
						}}
					/>,
					client && client.withoutPrinter ? (
						<CustomButton
							icon={<ToggleRight size={20} />}
							text={'Status'}
							onClickFunction={updateMultipleConnectionStatus}
						/>
					) : null,
				]}
				rightButtons={[
					<CustomButton
						icon={<Trash color="#f44336" size={20} />}
						text={'Delete'}
						onClickFunction={() => {
							setShowDeleteModal(true);
						}}
						colorClass={'red-500'}
					/>,
				]}
				table={
					<DataTable
						columns={columns}
						data={formattedPrinters.length > 0 ? formattedPrinters : []}
						customStyles={newCustomStyles}
						fixedHeader
						fixedHeaderScrollHeight="80vh"
						selectableRows={true}
						onSelectedRowsChange={handleChange}
						clearSelectedRows={toggledClearRows}
					/>
				}
				err={err}
			/>

			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative mx-auto w-[50vw] max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h3 className="text-3xl font-semibold">Update Printer</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setShowModal(false)}
									>
										<X size={22} />
									</button>
								</div>
								{/*body*/}
								<div className="relative py-6 flex-auto">
									<form onSubmit={updatePrinter} className="h-1/2 flex flex-col justify-between">
										<div className="grid grid-cols-1 px-6">
											{/* input fields for printer update */}

											<div className="flex justify-between gap-10">
												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Printer Name <span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="printer_name"
														name="printer_name"
														value={updatedPrinter['printer_name']}
														onChange={(e) =>
															setUpdatedPrinter({
																...updatedPrinter,
																printer_name: e.target.value,
															})
														}
													/>
												</div>

												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Printer Id <span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="printer_id"
														name="printer_id"
														value={updatedPrinter['printer_id']}
														onChange={(e) =>
															setUpdatedPrinter({
																...updatedPrinter,
																printer_id: e.target.value,
															})
														}
													/>
												</div>
											</div>

											<div className="flex justify-between gap-10 mt-4">
												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Manufacturer <span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="manufacturer"
														name="manufacturer"
														value={updatedPrinter['manufacturer']}
														onChange={(e) =>
															setUpdatedPrinter({
																...updatedPrinter,
																manufacturer: e.target.value,
															})
														}
													/>
												</div>

												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Technology <span className="text-red-500 after:content-['*']"></span>
													</label>
													<Select
														name="technology"
														id="technology"
														className=""
														required
														value={{
															label: updatedPrinter.technology,
															value: updatedPrinter.technology,
														}}
														onChange={(e) =>
															setUpdatedPrinter({
																...updatedPrinter,
																technology: e.value,
															})
														}
														options={options}
													></Select>
												</div>
											</div>

											{/* <input className='border border-slate-300 p-2' required type="text" placeholder='technology' name="technology" value={updatedPrinter['technology']} onChange={(e) => setUpdatedPrinter({ ...updatedPrinter, "technology": e.target.value })} /> */}

											<div className="flex justify-between gap-10 mt-4">
												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Material <span className="text-red-500 after:content-['*']"></span>
													</label>
													{/* <input className='rounded border border-slate-300 p-2' required type="text" placeholder='material' name="material" value={updatedPrinter['material']} onChange={(e) => setUpdatedPrinter({ ...updatedPrinter, "material": e.target.value })} /> */}
													<Select
														name="material"
														id="material"
														className=""
														required
														value={{
															label: updatedPrinter.material,
															value: updatedPrinter.material,
														}}
														onChange={(e) =>
															setUpdatedPrinter({
																...updatedPrinter,
																material: e.value,
															})
														}
														options={materialOptions}
													></Select>
												</div>

												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Color <span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="color"
														name="color"
														value={updatedPrinter['color']}
														onChange={(e) =>
															setUpdatedPrinter({
																...updatedPrinter,
																color: e.target.value,
															})
														}
													/>
												</div>
											</div>

											{!client.withoutPrinter ? (
												<div className="flex justify-between gap-10 mt-4">
													<div className="flex flex-col w-1/2">
														<label htmlFor="">
															IP Address <span className="text-red-500 after:content-['*']"></span>
														</label>
														<input
															className="rounded border border-slate-300 p-2"
															required
															type="text"
															placeholder="ip_address"
															name="ip_address"
															value={updatedPrinter['ip_address']}
															onChange={(e) =>
																setUpdatedPrinter({
																	...updatedPrinter,
																	ip_address: e.target.value,
																})
															}
														/>
													</div>

													<div className="flex flex-col w-1/2">
														<label htmlFor="">
															API Key <span className="text-red-500 after:content-['*']"></span>
														</label>
														<input
															className="rounded border border-slate-300 p-2"
															required
															type="text"
															placeholder="api_key"
															name="api_key"
															value={updatedPrinter['api_key']}
															onChange={(e) =>
																setUpdatedPrinter({
																	...updatedPrinter,
																	api_key: e.target.value,
																})
															}
														/>
													</div>
												</div>
											) : null}

                                            <div className="flex justify-start gap-10 mt-4">
												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Nozzle Size <span className="text-red-500 after:content-['*']"></span>
													</label>
													<Select
														name="nozzleSize"
														id="nozzleSize"
														className=""
														required
														value={{
                                                            label: updatedPrinter.nozzleSize,
                                                            value: updatedPrinter.nozzleSize,
                                                        }}
														onChange={(e) => setUpdatedPrinter({ ...updatedPrinter, nozzleSize: e.value })}
														options={nozzleSizeOptions}
													></Select>
												</div>

                                                <div className='w-1/2'></div>
											</div>

											{errUpdate && <div className="text-red-500 mt-2">{errUpdate}</div>}
										</div>
										<br />
										<div className="flex justify-between border-t px-6 pt-4">
											<CustomButton
												icon={<Trash color="#f44336" size={20} />}
												text={'Discard'}
												onClickFunction={(e) => {
													e.preventDefault();
													setShowModal(false);
												}}
												colorClass={'red-500'}
											/>
											<CustomButton
												icon={<Check color="#01A66C" size={20} />}
												text={'Save Changes'}
												colorClass={'green-500'}
											/>
											{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700	text-white' type="submit">Submit</button> */}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}

			{showDeleteModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*body*/}
								<div className="relative p-6 flex-auto">
									<div className="f-22">Are You Sure, You want to delete?</div>
									<div className="flex justify-between mt-10">
										<button
											className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
											onClick={() => {
												DeleteMultiplePrinter();
												setShowDeleteModal(false);
											}}
										>
											Yes
										</button>
										<button
											className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
											onClick={() => {
												setShowDeleteModal(false);
											}}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}

			{showCreateModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative mx-auto w-[50vw] max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h3 className="text-2xl font-semibold">Create Printer</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setShowCreateModal(false)}
									>
										<X size={22} />
									</button>
								</div>
								{/*body*/}
								<div className="relative py-6 flex-auto">
									<form
										onSubmit={(e) => {
											setShowCreateModal(false);
											createPrinter(e);
                                            setPrinter(defaultPrinter);
										}}
										className="h-1/2 flex flex-col justify-between"
									>
										<div className="grid grid-cols-1 px-6">
											{/* input fields for printer update */}
											<div className="flex justify-between gap-10">
												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Printer Name <span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="PrinterName"
														name="PrinterName"
														value={printer['printer_name']}
														onChange={(e) =>
															setPrinter({
																...printer,
																printer_name: e.target.value,
															})
														}
													/>
												</div>

												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Printer Id <span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="Printer ID"
														name="PrinterId"
														value={printer['printer_id']}
														onChange={(e) =>
															setPrinter({
																...printer,
																printer_id: e.target.value,
															})
														}
													/>
												</div>
											</div>

											<div className="flex justify-between gap-10 mt-4">
												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Manufacturer <span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="Manufacturer"
														name="Manufacturer"
														value={printer['manufacturer']}
														onChange={(e) =>
															setPrinter({
																...printer,
																manufacturer: e.target.value,
															})
														}
													/>
												</div>

												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Technology <span className="text-red-500 after:content-['*']"></span>
													</label>
													<Select
														name="technology"
														id="technology"
														className=""
														required
														value={options.value}
														onChange={(e) => setPrinter({ ...printer, technology: e.value })}
														options={options}
														// defaultValue={options[0]}
													></Select>
												</div>
											</div>

											<div className="flex justify-between gap-10 mt-4">
												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Material <span className="text-red-500 after:content-['*']"></span>
													</label>
													{/* <input className='rounded border border-slate-300 p-2' required type="text" placeholder='Material' name="Material" value={printer['material']} onChange={(e) => setPrinter({ ...printer, "material": e.target.value })} /> */}
													<Select
														name="material"
														id="material"
														className=""
														required
														value={materialOptions.value}
														onChange={(e) => setPrinter({ ...printer, material: e.value })}
														options={materialOptions}
													></Select>
												</div>

												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Color <span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="Color"
														name="Color"
														value={printer['color']}
														onChange={(e) => setPrinter({ ...printer, color: e.target.value })}
													/>
												</div>
											</div>

											{!client ? (
												<></>
											) : client.withoutPrinter ? (
												<></>
											) : (
												<div className="flex justify-between gap-10 mt-4">
													<div className="flex flex-col w-1/2">
														<label htmlFor="">
															IP Address <span className="text-red-500 after:content-['*']"></span>
														</label>
														<input
															className="rounded border border-slate-300 p-2"
															required
															type="text"
															placeholder="IpAddress"
															name="IpAddress"
															value={printer['ip_address']}
															onChange={(e) =>
																setPrinter({
																	...printer,
																	ip_address: e.target.value,
																})
															}
														/>
													</div>

													<div className="flex flex-col w-1/2">
														<label htmlFor="">
															API Key <span className="text-red-500 after:content-['*']"></span>
														</label>
														<input
															className="rounded border border-slate-300 p-2"
															required
															type="text"
															placeholder="ApiKey"
															name="ApiKey"
															value={printer['api_key']}
															onChange={(e) =>
																setPrinter({
																	...printer,
																	api_key: e.target.value,
																})
															}
														/>
													</div>
												</div>
											)}

                                            <div className="flex justify-start gap-10 mt-4">
												<div className="flex flex-col w-1/2">
													<label htmlFor="">
														Nozzle Size <span className="text-red-500 after:content-['*']"></span>
													</label>
													<Select
														name="nozzleSize"
														id="nozzleSize"
														className=""
														required
														value={{
															label: printer.nozzleSize,
															value: printer.nozzleSize,
														}}
														onChange={(e) => setPrinter({ ...printer, nozzleSize: e.value })}
														options={nozzleSizeOptions}
													></Select>
												</div>
                                                <div className='w-1/2'></div>
											</div>



											{err && <div className="text-red-500">{err}</div>}
										</div>
										<br />
										<div className="flex justify-between border-t px-6 py-4">
											<CustomButton
												icon={<Trash color="#f44336" size={20} />}
												text={'Discard'}
												onClickFunction={(e) => {
													e.preventDefault();
													setShowCreateModal(false);
												}}
												colorClass={'red-500'}
											/>
											<CustomButton
												icon={<Check color="#01A66C" size={20} />}
												text={'Save Changes'}
												colorClass={'green-500'}
											/>
											{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700	text-white' type="submit">Submit</button> */}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}

			<TooltipsContainer />
		</div>
	);
};

export default Printers;
