import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext';
import axios from 'axios';
import { API_CLIENT_URL } from '../config';

const SideBar = ({ active_page }) => {
	const [menu, setMenu] = useState(false);
	const [menu2, setMenu2] = useState(false);
	const [client, setClient] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const { user, logout, showMenu, setShowMenu } = useContext(AuthContext);

	let navigate = useNavigate();

	const getClient = async () => {
		setIsLoading(true);

		const res = await axios.get(API_CLIENT_URL + '/me', {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			console.log('client', res.data.client);
			setClient(res.data.client);
		} else {
			console.log(res.data);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		if (user && user.user_level != 'admin') {
			getClient();
		}
	}, [user]);

	return (
		<>
			<aside
				id="separator-sidebar"
				className={`z-40 ${
					showMenu ? 'w-72' : 'w-16'
				} flex flex-col gap-10  h-screen -translate-x-full sm:translate-x-0 bg-gray-800 transition-all ease-linear duration-250 px-3 py-4 overflow-hidden `}
				aria-label="Sidebar"
			>
				{/* button to expand and shrink */}
				<button onClick={() => setShowMenu(!showMenu)} className="flex justify-center text-white cursor-pointer">
					<img src={`../assets/img/${showMenu ? 'minus.png' : 'add.png'}`} width={24} alt="" />
				</button>
				<img
					src="../assets/img/logo_transparent.png"
					className={`h-20 mx-auto ${showMenu ? 'opacity-1' : 'opacity-0'} translate-all ease-linear duration-200`}
					alt="SAM Logo"
				/>
				<div className="flex flex-col flex-1 justify-between">
					{user && user.user_level !== 'admin' ? (
						<>
							<ul className="space-y-4 font-medium">
								<li>
									<Link
										to="/home"
										className={`flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group ${
											active_page == 'home' ? 'bg-gray-700' : ''
										}`}
									>
										<img src="../assets/img/home.png" width={24} alt="" />
										<span className="flex-1 ml-5 whitespace-nowrap">Home</span>
										{/* <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> */}
									</Link>
								</li>
								<li>
									<Link
										to="/gcodes"
										className={`flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group ${
											active_page == 'gcodes' ? 'bg-gray-700' : ''
										}`}
									>
										<img src="../assets/img/part.png" width={24} />
										<span className="flex-1 ml-5 whitespace-nowrap">Parts Management</span>
									</Link>
								</li>
								<li>
									<Link
										to="/orders"
										className={`flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group ${
											active_page == 'orders' ? 'bg-gray-700' : ''
										}`}
									>
										<img src="../assets/img/orders.png" width={24} />
										<span className="flex-1 ml-5 whitespace-nowrap">Orders Overview</span>
										{/* <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span> */}
									</Link>
								</li>
								<li>
									<Link
										to="/schedule?manual=1"
										className={`flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group ${
											active_page == 'schedule' ? 'bg-gray-700' : ''
										}`}
									>
										<img src="../assets/img/schedule.png" width={24} />
										<span className="flex-1 ml-5 whitespace-nowrap">Schedule Overview</span>
									</Link>
								</li>
								<li>
									<Link
										to="/printers"
										className={`flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group ${
											active_page == 'printers' ? 'bg-gray-700' : ''
										}`}
									>
										<img src="../assets/img/printer.png" width={24} />
										<span className="flex-1 ml-5 whitespace-nowrap">Printers Management</span>
									</Link>
								</li>

								{user.user_level == 'client_admin' || user.user_level == 'client_superAdmin' ? (
									<>
										<li>
											<Link
												to="/erp"
												className={`flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group ${
													active_page == 'erp' ? 'bg-gray-700' : ''
												}`}
											>
												<img src="../assets/img/erp.png" width={24} />
												<span className="flex-1 ml-5 whitespace-nowrap">ERP</span>
											</Link>
										</li>
										<li>
											<Link
												to="/users"
												className={`flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group ${
													active_page == 'users' ? 'bg-gray-700' : ''
												}`}
											>
												<img src="../assets/img/user.png" width={24} />
												<span className="flex-1 ml-5 whitespace-nowrap">User Management</span>
											</Link>
										</li>
									</>
								) : (
									<></>
								)}
							</ul>
						</>
					) : (
						<div></div>
					)}
					<div className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-700">
						<div className={`${showMenu ? 'text-white' : 'text-transparent'} transition-all ease-linear`}>
							{user.email}
						</div>
						<ul className="">
							<li>
								<Link
									to="/update-password"
									className={`flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group ${
										active_page == 'update-password' ? 'bg-gray-700' : ''
									}`}
								>
									<img src="../assets/img/credentials.png" width={24} />
									<span className="flex-1 ml-5 whitespace-nowrap">Credentials</span>
								</Link>
							</li>
							<li>
								<a
									onClick={() => {
										logout();
										navigate('/');
									}}
									className="flex items-center p-2 rounded-lg text-white hover:bg-gray-700 group"
								>
									<img src="../assets/img/signout.png" width={24} />
									<span className="flex-1 ml-5 whitespace-nowrap">Sign Out</span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</aside>
		</>
	);
};

export default SideBar;
