import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { API_GCODE_URL } from '../config';
import { AuthContext } from '../Contexts/AuthContext';

import SideBar from '../Components/SideBar';
import CustomButton from '../Components/CustomButton';
import Select from 'react-select';

import DataTable from 'react-data-table-component';

import { Check, CloudArrowUp, NotePencil, SpinnerGap, Trash, X } from '@phosphor-icons/react';
import { newCustomStyles } from '../Components/NewTableStyles';
import TooltipsContainer from '../Components/TooltipsContainer';
import { toast } from 'react-hot-toast';

const Gcodes = () => {
	const [showModal, setShowModal] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [err400, setErr400] = useState(null);
	const [err500, setErr500] = useState(null);
	const [err, setErr] = useState(null);
	const [gcodes, setGcodes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const gcodeUploadRef = useRef(null);

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [formattedGcodes, setFormattedGcodes] = useState([]);
	const [columns, setColumns] = useState([]);

	const [selectedRows, setSelectedRows] = React.useState(false);
	const [toggledClearRows, setToggleClearRows] = React.useState(false);

	const { user } = useContext(AuthContext);

	const handleChange = ({ selectedRows }) => {
		setSelectedRows(selectedRows);
	};

	const defaultGcode = {
		client_id: '',
		client_name: '',
		gcode_name: '',
		printing_time: '',
		piece_per_printer: '',
		technology: '',
		manufacturer: '',
		material: '',
		color: '',
        nozzleSize: "0,4 mm",
	};

	const [updatedGcode, setUpdatedGcode] = useState(defaultGcode);

	const [technologyOptions, setTechnologyOptions] = useState([]);
	const [materialOptions, setMaterialOptions] = useState([]);
    const [nozzleSizeOptions, setNozzleSizeOptions] = useState([]);

	const technology = ['FFF', 'SLA', 'SLS', 'SLM', 'DLP', 'FJM'];

	const material = ['PLA', 'ABS', 'PETG', 'TPU', 'Nylon', 'ASA', 'PVB', 'HIPS', 'PVA', 'PC', 'PEI', 'PEEK'];

    const nozzleSize = ["0,2 mm","0,4 mm","0,6 mm","0,8 mm","1,0 mm","1,6 mm"]

	const handleFileChange = (event) => {
		const formData = new FormData();
		const files = event.target.files;

		for (let i = 0; i < files.length; i++) {
			formData.append('files', files[i]);
		}

		console.log(formData);

		const toastId = toast.loading('Uploading Parts...');

		setUploading(true);
		axios
			.post(API_GCODE_URL, formData, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((response) => {
				console.log(response.data);
				getGcodes();
				setErr400(null);
				setErr500(null);
				setUploading(false);
				toast.success('Parts Uploaded Successfully', { id: toastId });
			})
			.catch((error) => {
				console.log(error);
				setUploading(false);
				toast.error('Error Occured, Please Try Again', { id: toastId });
				console.log('here before error');

				// concate list of errors

				let errorsList400 = error.response.data['failed']['400'];
				let errorsList500 = error.response.data['failed']['500'];

				// concate string in errors
				// add two lists a and b
				// errors = a.concat(b)
				let errors400 = errorsList400.join(', ');
				let errors500 = errorsList500.join(', ');

				setErr400(errors400 != '' ? errors400 : null);
				setErr500(errors500 != '' ? errors500 : null);
				console.log('here after error', error.response.data.message);
			});

		event.target.value = null;
	};

	const createGcode = async (e) => {
		e.preventDefault();
	};

	const getGcodes = async () => {
		const res = await axios.get(API_GCODE_URL, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			setGcodes(res.data['gcodes']);
			console.log(res.data);
			setIsLoading(false);
		} else {
			console.log(res.data);
			setErr(res.data);
			setIsLoading(false);
		}
	};

	const deleteGcode = async (client_id, gcode_name) => {
		const res = await axios.delete(API_GCODE_URL + `/${client_id}/${gcode_name}`, {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 204) {
			getGcodes();
		} else {
			console.log(res.data);
			setErr(res.data);
		}
	};

	const deleteMultipleGcodes = async () => {
		if (selectedRows.length === 0) {
			toast.error('Please select at least one row to delete');
			return;
		}

		const toastId = toast.loading('Deleting Parts...');

		let promises = selectedRows.map(async (row) => {
			console.log(row);
			const res = await axios.delete(API_GCODE_URL + `/${row.client_id}/${row.gcode_name}`, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			});
			if (res.status === 204) {
				console.log('deleted');
			}
		});
		await Promise.all(promises);
		toast.success('Parts Deleted Successfully', { id: toastId });

		await getGcodes();
		setToggleClearRows(!toggledClearRows);
        setSelectedRows([]);
	};

	const updateGcode = async (e) => {
		e.preventDefault();

		const toastId = toast.loading('Updating Part...');

		await axios
			.put(API_GCODE_URL + `/${updatedGcode.client_id}/${updatedGcode.gcode_name}`, updatedGcode, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			})
			.then((res) => {
				if (res.status === 201) {
					toast.success('Part Updated Successfully', { id: toastId });
					getGcodes();
					setErr(null);
					setShowModal(false);
				} else {
					toast.error('Error Occured, Please Try Again', { id: toastId });
					setErr(String(res.data));
				}
			})
			.catch((error) => {
				toast.error('Error Occured, Please Try Again', { id: toastId });
				setErr(error.response.data.message);
			});
	};

	useEffect(() => {
		console.log('user', user);
		if (!user) return;
		getGcodes();
	}, [user]);

	useEffect(() => {
		let technologyOptionsLocal = technology.map((tech) => {
			return {
				label: tech,
				value: tech,
			};
		});
		setTechnologyOptions(technologyOptionsLocal);

		let materialOptionsLocal = material.map((mat) => {
			return {
				label: mat,
				value: mat,
			};
		});
		setMaterialOptions(materialOptionsLocal);

        let nozzleSizeOptionsLocal = nozzleSize.map((nozzle) => {
            return {
                label: nozzle,
                value: nozzle,
            };
        });
        setNozzleSizeOptions(nozzleSizeOptionsLocal);

		let formattedGcodesLocal = gcodes.map((gcode) => {
			return {
				client_id: gcode.client_id,
				client_name: gcode.client_name,
				gcode_name: gcode.gcode_name,
				printing_time: gcode.printing_time,
				piece_per_printer: gcode.piece_per_printer,
				technology: gcode.technology,
				manufacturer: gcode.manufacturer,
				material: gcode.material,
				color: gcode.color,
                nozzleSize: gcode.nozzleSize,
				actions: (
					<div className=" p-2 flex gap-6">
						{/* <img src="../assets/img/delete.svg" alt="" className='w-6' onClick={() => { setDeleteGcodeName(gcode.gcode_name); setDeleteGcodeClientId(gcode.client_id); setShowDeleteModal(true) }} /> */}

						<button
							onClick={() => {
								setUpdatedGcode(gcode);
								setShowModal(true);
							}}
						>
							<NotePencil size={20} />
						</button>
					</div>
				),
			};
		});
		setFormattedGcodes(formattedGcodesLocal);
		const columnsLocal = [
			{
				name: 'Client ID',
				selector: 'client_id',
				sortable: true,
				omit: true,
				reorder: true,
			},
			{
				name: 'Client Name',
				selector: 'client_name',
				sortable: true,
				omit: true,
				reorder: true,
			},
			{
				name: 'Gcode Name',
				selector: 'gcode_name',
				sortable: true,
				cell: (row) => <div className="font-bold">{row.gcode_name}</div>,
				reorder: true,
			},
			{
				name: 'Printing Time',
				selector: 'printing_time',
				sortable: true,
				reorder: true,
			},
			{
				name: 'Piece per Printer',
				selector: 'piece_per_printer',
				sortable: true,
				reorder: true,
			},
			{
				name: 'Technology',
				selector: 'technology',
				sortable: true,
				reorder: true,
			},
			{
				name: 'Manufacturer',
				selector: 'manufacturer',
				sortable: true,
				reorder: true,
			},
			{
				name: 'Material',
				selector: 'material',
				sortable: true,
				reorder: true,
			},
			{
				name: 'Color',
				selector: 'color',
				sortable: true,
				reorder: true,
			},
            {
                name: 'Nozzle Diameter',
                selector: 'nozzleSize',
                sortable: true,
                reorder: true,
            },
			{
				name: 'Actions',
				selector: 'actions',
				sortable: true,
				reorder: true,
			},
		];
		setColumns(columnsLocal);
	}, [gcodes]);

	return (
		<div className="flex w-full">
			<SideBar active_page={'gcodes'} />

			<div className="py-10 px-10 h-screen overflow-auto flex-1">
				<>
					{isLoading ? (
						<div className="flex justify-center items-center h-[80vh] ">
							<p className="text-5xl font-bold text-gray-400">Loading...</p>
						</div>
					) : (
						<div className="bg-white relative overflow-auto">
							{!uploading && (
								<>
									{err400 && <div className="text-red-500">These Gcodes Already Exists: {err400}</div>}
									{err500 && <div className="text-red-500">Error Occured, contact admin: {err500}</div>}
								</>
							)}
							<div className="flex justify-between mb-4">
								<form className="w-1/3" onSubmit={createGcode}>
									<label for="gcodeUpload" className="">
										<CustomButton
											text="Upload Parts"
											onClickFunction={() => {
												gcodeUploadRef.current.click();
											}}
											disabled={uploading}
											icon={
												<div className={uploading ? 'animate-spin' : ''}>
													{uploading ? <SpinnerGap size={20} /> : <CloudArrowUp size={20} />}
												</div>
											}
										/>

										<input
											ref={gcodeUploadRef}
											type="file"
											id="gcodeUpload"
											name="files"
											className="hidden"
											multiple
											onChange={(e) => {
												handleFileChange(e);
											}}
										/>
									</label>
								</form>
								<div className="w-1/3 text-3xl text-center">Parts Management</div>

								{
									<div className="w-1/3 flex justify-end">
										<CustomButton
											icon={<Trash color="#f44336" size={20} />}
											text={'Delete'}
											onClickFunction={() => {
												setShowDeleteModal(true);
											}}
											colorClass={'red-500'}
										/>
									</div>
								}
							</div>

							<DataTable
								columns={columns}
								data={formattedGcodes}
								customStyles={newCustomStyles}
								fixedHeader
								fixedHeaderScrollHeight="80vh"
								selectableRows={true}
								onSelectedRowsChange={handleChange}
								clearSelectedRows={toggledClearRows}
							/>

							{showModal ? (
								<>
									<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
										<div className="relative mx-auto w-[50vw] max-w-3xl">
											{/*content*/}
											<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
												{/*header*/}
												<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
													<h3 className="text-3xl font-semibold">Update Part</h3>
													<button
														className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
														onClick={() => setShowModal(false)}
													>
														<X size={22} />
													</button>
												</div>
												{/*body*/}
												<div className="relative py-6 flex-auto">
													<form onSubmit={updateGcode} className="h-1/2 flex flex-col justify-between">
														<div className="grid grid-cols-1 px-6">
															<div className="flex justify-center gap-10">
																{/* <div className='flex flex-col w-1/2'>
                                                    <label className='text-slate-700'>Client ID<span className="text-red-500 after:content-['*']"></span></label>
                                                    <input className='rounded border border-slate-300 p-2' required type="text" placeholder='client_name' name="client_name" value={updatedGcode['client_name']} disabled />
                                                </div> */}

																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		Gcode Name
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="rounded border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="gcode_name"
																		name="gcode_name"
																		value={updatedGcode['gcode_name']}
																		disabled
																	/>
																</div>
															</div>

															<div className="flex justify-between gap-10 mt-10">
																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		Printing Time
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="rounded border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="printing_time"
																		name="printing_time"
																		value={updatedGcode['printing_time']}
																		onChange={(e) =>
																			setUpdatedGcode({
																				...updatedGcode,
																				printing_time: e.target.value,
																			})
																		}
																	/>
																</div>

																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		Piece per Printer
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="rounded border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="piece_per_printer"
																		name="piece_per_printer"
																		value={updatedGcode['piece_per_printer']}
																		onChange={(e) =>
																			setUpdatedGcode({
																				...updatedGcode,
																				piece_per_printer: e.target.value,
																			})
																		}
																	/>
																</div>
															</div>

															<div className="flex justify-between gap-10 mt-10">
																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		Technology
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	{/* <input className='rounded border border-slate-300 p-2' required type="text" placeholder='technology' name="technology" value={updatedGcode['technology']} onChange={(e) => setUpdatedGcode({ ...updatedGcode, "technology": e.target.value })} /> */}
																	<Select
																		name="technology"
																		options={technologyOptions}
																		value={{
																			label: updatedGcode['technology'],
																			value: updatedGcode['technology'],
																		}}
																		onChange={(e) =>
																			setUpdatedGcode({
																				...updatedGcode,
																				technology: e.value,
																			})
																		}
																	/>
																</div>

																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		Manufacturer
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="rounded border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="manufacturer"
																		name="manufacturer"
																		value={updatedGcode['manufacturer']}
																		onChange={(e) =>
																			setUpdatedGcode({
																				...updatedGcode,
																				manufacturer: e.target.value,
																			})
																		}
																	/>
																</div>
															</div>

															<div className="flex justify-between gap-10 mt-10">
																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		Material
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	{/* <input className='rounded border border-slate-300 p-2' required type="text" placeholder='material' name="material" value={updatedGcode['material']} onChange={(e) => setUpdatedGcode({ ...updatedGcode, "material": e.target.value })} /> */}
																	<Select
																		name="material"
																		options={materialOptions}
																		value={{
																			label: updatedGcode['material'],
																			value: updatedGcode['material'],
																		}}
																		onChange={(e) =>
																			setUpdatedGcode({
																				...updatedGcode,
																				material: e.value,
																			})
																		}
																	/>
																</div>

																<div className="flex flex-col w-1/2">
																	<label className="text-slate-700">
																		Color
																		<span className="text-red-500 after:content-['*']"></span>
																	</label>
																	<input
																		className="rounded border border-slate-300 p-2"
																		required
																		type="text"
																		placeholder="color"
																		name="color"
																		value={updatedGcode['color']}
																		onChange={(e) =>
																			setUpdatedGcode({
																				...updatedGcode,
																				color: e.target.value,
																			})
																		}
																	/>
																</div>
															</div>

                                                            <div className="flex justify-start gap-10 mt-4">
                                                                <div className="flex flex-col w-1/2">
                                                                    <label htmlFor="">
                                                                        Nozzle Size <span className="text-red-500 after:content-['*']"></span>
                                                                    </label>
                                                                    <Select
                                                                        name="nozzleSize"
                                                                        id="nozzleSize"
                                                                        className=""
                                                                        required
                                                                        value={{
                                                                            label: updatedGcode.nozzleSize,
                                                                            value: updatedGcode.nozzleSize,
                                                                        }}
                                                                        onChange={(e) => setUpdatedGcode({ ...updatedGcode, nozzleSize: e.value })}
                                                                        options={nozzleSizeOptions}
                                                                    ></Select>
                                                                </div>
                                                                <div className='w-1/2'></div>
                                                            </div>

															{err && <div className="text-red-500">{err}</div>}
														</div>
														<br />
														<div className="flex justify-between border-t px-6 py-2">
															<CustomButton
																icon={<Trash color="#f44336" size={20} />}
																text={'Discard'}
																onClickFunction={(e) => {
																	e.preventDefault();
																	setShowModal(false);
																}}
																colorClass={'red-500'}
															/>
															<CustomButton
																icon={<Check color="#01A66C" size={20} />}
																text={'Save Changes'}
																colorClass={'green-500'}
															/>
															{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700	text-white' type="submit">Submit</button> */}
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
									<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
								</>
							) : null}

							{showDeleteModal ? (
								<>
									<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
										<div className="relative w-auto my-6 mx-auto max-w-3xl">
											{/*content*/}
											<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
												{/*body*/}
												<div className="relative p-6 flex-auto">
													<div className="f-22">Are You Sure, You want to delete?</div>
													<div className="flex justify-between mt-10">
														<button
															className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
															onClick={() => {
																deleteMultipleGcodes();
																setShowDeleteModal(false);
															}}
														>
															Yes
														</button>
														<button
															className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
															onClick={() => {
																setShowDeleteModal(false);
															}}
														>
															No
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
								</>
							) : null}
						</div>
					)}
				</>
			</div>

			<TooltipsContainer />
		</div>
	);
};

export default Gcodes;
