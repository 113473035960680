import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
	const { login, user } = useContext(AuthContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	let navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await login({
				email: email,
				password: password,
			});
		} catch (err) {
			console.log(err.data);
			setError(err.message);
		}
	};

	useEffect(() => {
		if (user && user.level == 'admin') {
			navigate('/dashboard');
		} else if (user && user.user_type == 'client') {
			navigate('/orders');
		}
	}, [user]);

	return (
		<>
			<div className="h-screen md:flex">
				<div className="relative bg-darkGrey overflow-hidden md:flex w-1/2 i justify-around items-center hidden">
					<div className="md:flex items-center justify-center">
						<img src="../assets/img/samLogo.jpeg" alt="" className="max-w-xs" />
					</div>
				</div>
				<div className="flex md:w-1/2 justify-center py-10 items-center bg-blue-800">
					<form className="bg-transparent" onSubmit={handleSubmit}>
						<div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 text-gray-400"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
								/>
							</svg>
							<input
								className="pl-2 outline-none border-none bg-transparent text-white"
								type="email"
								value={email}
								placeholder="Email Address"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="flex items-center border-2 py-2 px-3 rounded-2xl">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 text-gray-400"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
									clipRule="evenodd"
								/>
							</svg>
							<input
								className="pl-2 outline-none border-none bg-transparent text-white"
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								placeholder="Password"
							/>
						</div>
						<button
							type="submit"
							className="block w-full bg-white mt-8 py-2 rounded-2xl text-blue-900 font-semibold mb-2"
						>
							Login
						</button>
						{error && <p>{error}</p>}
					</form>
				</div>
			</div>
		</>
	);
};

export default Login;
