import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Login";
import LandingPage from "./Pages/LandingPage";
import Dashboard from "./Pages/Dashboard";
import ChartDetail from "./Pages/ChartDetail";
import { useContext } from "react";
import { AuthContext } from "./Contexts/AuthContext";
import Orders from "./Pages/Orders";
import Page404 from "./Pages/404";
import Gcodes from "./Pages/gcodes";
import ErpDetails from "./Pages/ErpDetails";
import Users from "./Pages/Users";
import UpdatePassword from "./Pages/UpdatePassword";
import Printers from "./Pages/printers";
import Home from "./Pages/Home";

function App() {
  const { user, login, checkAuth, getToken } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <div className="font-Nunito">
        {!user && (
          <Routes>
            <Route path="/" exact element={<LandingPage />} />
            <Route path="/login" exact element={<Login />} />
          </Routes>
        )}
        {user && user.user_level === "admin" && (
          <Routes>
            <Route path="/login" element={<Navigate replace to="/dashboard" />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
          </Routes>
        )}
        {user && (user.user_level === "client_user" || user.user_level === "client_admin" || user.user_level === "client_superAdmin") && (
          <Routes>
            <Route path="/login" element={<Navigate replace to="/home" />} />
            <Route path="/home" exact element={<Home />} />
            <Route path="/orders" exact element={<Orders />} />
            <Route path="/gcodes" exact element={<Gcodes />} />
            <Route path="/printers" exact element={<Printers />} />
            <Route path="/schedule" exact element={<ChartDetail />} />
            <Route path="/update-password" exact element={<UpdatePassword />} />
            {user.user_level === "client_admin" || user.user_level === "client_superAdmin" ? (
              <>
                <Route path="/erp" exact element={<ErpDetails />} />
                <Route path="/users" exact element={<Users />} />
              </>
            ) : (
              <></>
            )}
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
