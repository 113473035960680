export const newCustomStyles = {
	// table: {
	//     style: {
	//         paddingRight: '10px',
	//     }
	// },
	headRow: {
		style: {
			border: '1px solid transparent',
			backgroundColor: '#202A37',
		},
	},
	headCells: {
		style: {
			fontWeight: 'regular',
			fontSize: '12px',
			color: '#ffffff',
			paddingLeft: '18px', // to accomodate the arrow
			justifyContent: 'center',
			// convert all to uppercase
			textTransform: 'uppercase',
		},
	},
	rows: {
		style: {
			minHeight: '72px', // override the row height
			border: '1px solid rgb(203 213 225)',
			marginTop: '5px',
			marginBottom: '5px',
			borderRadius: '4px',
		},
	},

	cells: {
		style: {
			fontWeight: 'regular',
			fontSize: '14px',
			color: '#405661',
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
		},
	},
};
