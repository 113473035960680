import { Check, NotePencil, PlusCircle, Trash, X } from '@phosphor-icons/react';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { newCustomStyles } from './NewTableStyles';
import CustomButton from './CustomButton';
import axios from 'axios';
import { API_CLIENT_USER_URL } from '../config';
import { AuthContext } from '../Contexts/AuthContext';
import { toast } from 'react-hot-toast';

function AddClientUser({
	clients,
	getClientUsers,
	clientUsers,
	deleteClientUser,
	clientUser,
	err,
	setClientUser,
	createClientUser,
	updateClientUser,
	updatedClientUser,
	setUpdatedClientUser,
	showModal,
	setShowModal,
}) {
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [options, setOptions] = useState([]);

	const [formattedClientUsers, setFormattedClientUsers] = useState([]);
	const [columns, setColumns] = useState([]);

	const [selectedRows, setSelectedRows] = React.useState(false);
	const [toggledClearRows, setToggleClearRows] = React.useState(false);

	const { user } = useContext(AuthContext);

	const [optionsClientProfile, setOptionsClientProfile] = useState([
		{ value: 'client_admin', label: 'client_admin' },
		{ value: 'client_user', label: 'client_user' },
	]);

	const deleteMultipleClientUsers = async () => {
		if (selectedRows.length === 0) {
			toast.error('Please select at least one row to delete');
			return;
		}

		const toastId = toast.loading('Deleting Client Users');

		let promises = selectedRows.map(async (row) => {
			console.log('row', row);
			const res = await axios.delete(API_CLIENT_USER_URL + `/${row.client_id}/${row.user_email}`, {
				headers: {
					Authorization: `Bearer ${user.access_token}`,
				},
			});
			if (res.status === 204) {
				console.log('deleted');
			}
		});
		await Promise.all(promises);
		toast.success('Client Users Deleted Successfully', { id: toastId });
		await getClientUsers();
		setToggleClearRows(!toggledClearRows);
        setSelectedRows([]);
	};

	const handleChange = ({ selectedRows }) => {
		setSelectedRows(selectedRows);
	};

	const handleCreateClientUser = async (e) => {
		e.preventDefault();
		setShowCreateModal(false);
		const toastId = toast.loading('Creating Client User');
		await createClientUser(e);

		toast.success('Client User Created Successfully', { id: toastId });
	};

	useEffect(() => {
		var clientNames = [];
		clients.map((client) => {
			clientNames.push({
				value: client.id,
				name: client.name,
				label: client.name,
			});
		});
		console.log(clientNames);
		setOptions(clientNames);
	}, [clients]);

	useEffect(() => {
		var formattedClientUsersLocal = clientUsers.map((clientUser) => {
			return {
				client_id: clientUser.client_id,
				user_profile: clientUser.user_profile,
				user_email: clientUser.user_email,
				user_password: clientUser.user_password,
				created_at: clientUser.created_at,
				actions: (
					<div className=" p-2 flex gap-6">
						{/* <img src="../assets/img/delete.svg" alt="" className='w-6' onClick={() => { setDeleteGcodeName(gcode.gcode_name); setDeleteGcodeClientId(gcode.client_id); setShowDeleteModal(true) }} /> */}

						<button
							onClick={() => {
								setUpdatedClientUser(clientUser);
								setShowModal(true);
							}}
						>
							<NotePencil size={20} />
						</button>
					</div>
				),
			};
		});
		setFormattedClientUsers(formattedClientUsersLocal);

		var columnsLocal = [
			{
				name: 'Client ID',
				selector: 'client_id',
				omit: true,
				reorder: true,
			},
			{
				name: 'User Profile',
				selector: 'user_profile',
				sortable: true,
				reorder: true,
			},
			{
				name: 'User Email',
				selector: 'user_email',
				sortable: true,
				reorder: true,
			},
			{
				name: 'User Password',
				selector: 'user_password',
				reorder: true,
			},
			{
				name: 'Creation Date',
				selector: 'created_at',
				sortable: true,
				reorder: true,
			},
			{
				name: 'Actions',
				selector: 'actions',
				reorder: true,
			},
		];
		setColumns(columnsLocal);
	}, [clientUsers]);

	return (
		<div className="bg-white relative overflow-auto px-10 py-10">
			<div className="flex justify-between mb-2">
				<div className="w-1/3">
					<CustomButton
						icon={<PlusCircle size={20} />}
						text={'Add User'}
						onClickFunction={() => {
							setShowCreateModal(true);
						}}
					/>
				</div>
				<div className="w-1/3 text-3xl text-center">User Management</div>
				<div className="w-1/3 flex justify-end">
					<CustomButton
						icon={<Trash color="#f44336" size={20} />}
						text={'Delete'}
						onClickFunction={() => {
							setShowDeleteModal(true);
						}}
						colorClass={'red-500'}
					/>
				</div>
			</div>

			<DataTable
				columns={columns}
				data={formattedClientUsers}
				customStyles={newCustomStyles}
				fixedHeader
				fixedHeaderScrollHeight="80vh"
				selectableRows={true}
				onSelectedRowsChange={handleChange}
				clearSelectedRows={toggledClearRows}
			/>

			{showModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative mx-auto w-[50vw] max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h3 className="text-3xl font-semibold">Update User</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setShowModal(false)}
									>
										<X size={22} />
									</button>
								</div>
								{/*body*/}
								<div className="relative py-6 flex-auto">
									<form onSubmit={updateClientUser} className="h-1/2 flex flex-col justify-between">
										<div className="grid grid-cols-1 px-6">
											<div className="flex justify-between gap-10">
												<div className="flex flex-col w-1/2">
													<label className="">User Profile</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="user_profile"
														name="user_profile"
														value={updatedClientUser['user_profile']}
														disabled
													/>
												</div>

												<div className="flex flex-col w-1/2">
													<label className="">User Email</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="email"
														placeholder="user_email"
														name="user_email"
														value={updatedClientUser['user_email']}
														disabled
													/>
												</div>
											</div>

											<div className="flex justify-between gap-10 mt-4">
												<div className="flex flex-col w-1/2">
													<label className="">
														User Password
														<span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="user_password"
														name="user_password"
														value={updatedClientUser['user_password']}
														onChange={(e) =>
															setUpdatedClientUser({
																...updatedClientUser,
																user_password: e.target.value,
															})
														}
													/>
												</div>

												<div className="flex flex-col w-1/2">
													<label className="">Creation Date</label>
													<input
														className="rounded border border-slate-300 p-2"
														required
														type="text"
														placeholder="created_at"
														name="created_at"
														value={updatedClientUser['created_at']}
														disabled
													/>
												</div>
											</div>

											{/* <input className='border border-slate-300 p-2' required type="text" placeholder='client_name' name="client_name" value={updatedClientUser['client_name']} disabled /> */}
											{/* <input className='border border-slate-300 p-2' required type="text" placeholder='user_id' name="user_id" value={updatedClientUser['user_id']} disabled /> */}
											{/* <input className='border border-slate-300 p-2' required type="text" placeholder='user_profile' name="user_profile" value={updatedClientUser['user_profile']} onChange={(e) => setUpdatedClientUser({ ...updatedClientUser, "user_profile": e.target.value })} /> */}

											{/* <input className='border border-slate-300 p-2' required type="text" placeholder='shift_time' name="shift_time" value={updatedClientUser['shift_time']} onChange={(e) => setUpdatedClientUser({ ...updatedClientUser, "shift_time": e.target.value })} /> */}

											{err && <div className="text-red-500">{err}</div>}
										</div>
										<br />
										<div className="flex justify-between border-t px-6 py-4">
											<CustomButton
												icon={<Trash color="#f44336" size={20} />}
												text={'Discard'}
												onClickFunction={(e) => {
													e.preventDefault();
													setShowModal(false);
												}}
												colorClass={'red-500'}
											/>
											<CustomButton
												icon={<Check color="#01A66C" size={20} />}
												text={'Save Changes'}
												colorClass={'green-500'}
											/>
											{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700	text-white' type="submit">Submit</button> */}
											{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white' onClick={() => { setShowModal(false) }}>Cancel</button> */}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}

			{showCreateModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative mx-auto w-[50vw] max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*header*/}
								<div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
									<h3 className="text-3xl font-semibold">Create User</h3>
									<button
										className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
										onClick={() => setShowCreateModal(false)}
									>
										<X size={22} />
									</button>
								</div>
								{/*body*/}
								<div className="relative py-6 flex-auto">
									<form onSubmit={handleCreateClientUser} className="h-1/2 flex flex-col justify-between">
										<div className="grid grid-cols-1 px-6">
											<div className="flex justify-between gap-10">
												<div className="flex flex-col w-1/2">
													<label className="">
														User Profile
														<span className="text-red-500 after:content-['*']"></span>
													</label>
													<Select
														name="UserProfile"
														id="UserProfile"
														className=""
														required
														value={optionsClientProfile.value}
														onChange={(e) =>
															setClientUser({
																...clientUser,
																user_profile: e.value,
															})
														}
														options={optionsClientProfile}
													></Select>
												</div>

												<div className="flex flex-col w-1/2">
													<label className="">
														User Email
														<span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="border border-slate-300 p-2"
														required
														type="email"
														placeholder="User Email"
														name="UserEmail"
														value={clientUser['user_email']}
														onChange={(e) =>
															setClientUser({
																...clientUser,
																user_email: e.target.value,
															})
														}
													/>
												</div>
											</div>

											<div className="flex justify-between gap-10 mt-4">
												<div className="flex flex-col w-1/2">
													<label className="">
														Client Password
														<span className="text-red-500 after:content-['*']"></span>
													</label>
													<input
														className="border border-slate-300 p-2"
														required
														type="text"
														placeholder="User Password"
														name="UserPassword"
														value={clientUser['user_password']}
														onChange={(e) =>
															setClientUser({
																...clientUser,
																user_password: e.target.value,
															})
														}
													/>
												</div>

												<div className="flex flex-col w-1/2">
													<label className="">Creation Date</label>
													<input
														className="border border-slate-300 p-2"
														required
														type="text"
														placeholder="Creation Date"
														name="CreationDate"
														value={clientUser['creation_date']}
														disabled
													/>
												</div>
											</div>

											{err && <div className="text-red-500">{err}</div>}
										</div>
										<br />
										<div className="flex justify-between border-t px-6 py-4">
											<CustomButton
												icon={<Trash color="#f44336" size={20} />}
												text={'Discard'}
												onClickFunction={(e) => {
													e.preventDefault();
													setShowCreateModal(false);
												}}
												colorClass={'red-500'}
											/>
											<CustomButton
												icon={<Check color="#01A66C" size={20} />}
												text={'Save Changes'}
												colorClass={'green-500'}
											/>
											{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700	text-white' type="submit">Submit</button> */}
											{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white' onClick={() => { setShowModal(false) }}>Cancel</button> */}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}

			{showDeleteModal ? (
				<>
					<div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
						<div className="relative w-auto my-6 mx-auto max-w-3xl">
							{/*content*/}
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								{/*body*/}
								<div className="relative p-6 flex-auto">
									<div className="f-22">Are You Sure, You want to delete?</div>
									<div className="flex justify-between mt-10">
										<button
											className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
											onClick={() => {
												deleteMultipleClientUsers();
												setShowDeleteModal(false);
											}}
										>
											Yes
										</button>
										<button
											className="border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white"
											onClick={() => {
												setShowDeleteModal(false);
											}}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</div>
	);
}

export default AddClientUser;
