import React, { useEffect } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function TooltipsContainer() {
	useEffect(() => {
		const elements = document.querySelectorAll('.sc-crozmw.jlPZUL');
		let index = 0;
		elements.forEach((element) => {
			element.setAttribute('data-tooltip-content', element.innerHTML);
			element.setAttribute('data-tooltip-id', `tooltip-${index}`);
			element.setAttribute('data-tooltip-variant', 'warning');
			console.log(element.innerHTML);
			index++;
		});
	}, [document.querySelectorAll('.sc-crozmw.jlPZUL')]);

	// Find all elements with the specified class and create tooltips
	const tooltips = Array.from(document.querySelectorAll('.sc-crozmw.jlPZUL')).map((element, index) => (
		<div key={index}>
			{/* <span
        data-tooltip-content={element.innerHTML}
        data-tooltip-id={`tooltip-${index}`}
      >
        {element.innerHTML}
      </span> */}
			<ReactTooltip
				id={`tooltip-${index}`}
				place="top"
				style={{
					zIndex: 99999,
				}}
			/>
		</div>
	));

	return <div>{tooltips}</div>;
}

export default TooltipsContainer;
