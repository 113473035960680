import React from 'react';

function CustomButton({ text, onClickFunction, icon = null, colorClass = null, disabled = false }) {
	return (
		<button
			className={`flex items-center gap-2 border ${
				colorClass == null ? 'border-[#E5E7EB]' : 'border-' + colorClass
			} rounded py-2 px-2 cursor-pointer`}
			onClick={onClickFunction}
			disabled={disabled}
		>
			{icon == null ? <></> : icon}
			<div className={`${colorClass == null ? 'text-[#374151]' : 'text-' + colorClass} font-semibold text-sm`}>
				{text}
			</div>
		</button>
	);
}

export default CustomButton;
