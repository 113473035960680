import React, { useContext, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import CommonMenu from '../Components/CommonMenu';
import ReactLoading from 'react-loading';
import { AuthContext } from '../Contexts/AuthContext';
import { API_CLIENT_URL } from '../config';
import axios from 'axios';
import { PrintersContext } from '../Contexts/PrintersContext';
import { useSearchParams } from 'react-router-dom';
import SideBar from '../Components/SideBar';

function ChartDetail() {
	const {
		isPaused,
		chartSchedule,
		scheduleLoading,
		setIsPaused,
		printers,
		triggerReschedule,
		setTriggerReschedule,
		isManual,
		manualPrint,
		setIsManual,
	} = useContext(PrintersContext);

	const [shift_start, setShiftStart] = useState(null);
	const [shift_end, setShiftEnd] = useState(null);
	const [client, setClient] = useState({});

	useEffect(() => {
		if (client.shift_start !== undefined && client.shift_end !== undefined) {
			setShiftStart(
				new Date(client.shift_start).toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
				})
			);
			setShiftEnd(
				new Date(client.shift_end).toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
				})
			);
		}
	}, [client]);

	useEffect(() => {
		getClient();
	}, []);

	const getClient = async () => {
		const res = await axios.get(API_CLIENT_URL + '/me', {
			headers: {
				Authorization: `Bearer ${user.access_token}`,
			},
		});
		if (res.status === 200) {
			console.log('client', res.data.client);
			setClient(res.data.client);
		} else {
			console.log(res.data);
		}
	};

	const { user } = useContext(AuthContext);

	const columns = [
		{ type: 'string', id: 'Printer_name' },
		{ type: 'string', id: 'item_name' },
		{ type: 'string', role: 'tooltip', p: { html: true } },
		{ type: 'string', id: 'style', role: 'style' },
		{ type: 'date', id: 'Start' },
		{ type: 'date', id: 'End' },
		{ type: 'string', role: 'extra' },
		{ type: 'string', role: 'extra' },
	];

	const [rows, setRows] = useState([]);

	const data = [columns, ...rows];

	const options = {
		width: '100%',
		title: 'Timeline Chart',
		tooltip: {
			isHtml: true,
		},
	};

	const updateShift = async () => {
		const today = new Date();
		const shift_start_date = new Date(`${today.toDateString()} ${shift_start}`);
		const shift_end_date = new Date(`${today.toDateString()} ${shift_end}`);

		if (shift_start_date < today || shift_end_date < today) {
			alert('Shift start time cannot be less than current time');
			return;
		}
		if (shift_start_date > shift_end_date) {
			alert('Shift start time cannot be greater than shift end time');
			return;
		}

		const shift_start_iso = shift_start_date.toISOString();
		const shift_end_iso = shift_end_date.toISOString();

		try {
			const res = await axios.put(
				API_CLIENT_URL + '/me/shift',
				{
					shift_start: shift_start_iso,
					shift_end: shift_end_iso,
				},
				{
					headers: {
						Authorization: `Bearer ${user.access_token}`,
					},
				}
			);

			if (res.status === 200) {
				alert('Shift updated successfully');
				// setSchedule(await getSchedule());
				setTriggerReschedule((triggerReschedule + 1) % 2);
				getClient();
			}
		} catch (err) {
			console.log(err);
			// if status is 400, then alert ("overall shift time is not changed")
			if (err.response.status === 400) {
				alert('Overall shift time is not changed');
			}
		}
	};

	useEffect(() => {
		console.log('schedule overview chartSchedule', chartSchedule);
		if (chartSchedule !== undefined && chartSchedule !== null) {
			let rows = [
				[
					'Printers',
					new Date().toLocaleTimeString(),
					'<style>.google-visualization-tooltip {{white-space: nowrap; padding:10px;}}</style><span>Current Time</span>',
					'#000000',
					new Date(),
					new Date(),
					't',
					'',
				],
				...chartSchedule,
				[
					'Printers',
					new Date().toLocaleTimeString(),
					'<style>.google-visualization-tooltip {{white-space: nowrap; padding:10px;}}</style><span>Current Time</span>',
					'#000000',
					new Date(),
					new Date(),
					't',
					'',
				],
			];
			setRows(rows);
		}
	}, [chartSchedule]);

	const [searchParams] = useSearchParams();

	useEffect(() => {
		// Disable Automatic print
		// if (searchParams.get('manual') === "0") {
		// 	setIsManual(false);
		// }
		// else {
		// 	setIsManual(true);
		// }
	}, [searchParams]);

	return (
		<div className="flex w-full">
			<SideBar active_page={'schedule'} />
			{scheduleLoading ? (
				<div className="flex items-center justify-center h-screen flex-1">
					<ReactLoading type="spin" color="#0000ff" />
				</div>
			) : (
				<div className="bg-white relative overflow-auto px-5 py-5 h-screen flex-1">
					<div className="text-3xl mb-2 text-center">Schedule Overview</div>
					{/* Disable Automatic print */}
					{/* <div>
							<div className="flex gap-1">
								Choose Shift Start Time
								<input className="border border-slate-300 px-1 rounded" type="time" id="appt" name="shift_start" onChange={(e) => { setShiftStart(e.target.value) }} value={shift_start} />
							</div>
							<div className="flex gap-1 mt-4">
								Choose Shift End Time
								<input className="border border-slate-300 px-1 rounded" type="time" id="appt" name="shift_end" onChange={(e) => { setShiftEnd(e.target.value) }} value={shift_end} />
							</div>
						</div> */}

					<div className="flex mt-2 mb-4 gap-4">
						{/* Disable Automatic print */}
						{/* <button className='border border-slate-300 rounded px-4 py-1 bg-slate-700 text-white' onClick={() => { updateShift() }}>Update Shift</button> */}
						{console.log('client withoutPrinter', client.withoutPrinter)}
						{!client || client.withoutPrinter == undefined || client.withoutPrinter ? (
							<></>
						) : !isManual ? (
							<>
								<button
									className={`border border-slate-300 rounded px-4 py-1 ${
										isPaused ? 'bg-slate-700' : 'bg-gray-300'
									} text-white`}
									onClick={() => {
										setIsPaused(false);
									}}
								>
									Print
								</button>
								<button
									className={`border border-slate-300 rounded px-4 py-1 ${
										!isPaused ? 'bg-slate-700' : 'bg-gray-300'
									} text-white`}
									onClick={() => {
										setIsPaused(true);
									}}
								>
									Pause
								</button>
							</>
						) : (
							<>
								<button
									className={`border border-slate-300 rounded px-4 py-1 bg-slate-700 hover:bg-gray-300 text-white`}
									onClick={() => {
										manualPrint();
									}}
								>
									Print
								</button>
							</>
						)}
					</div>

					{
						// get len of Operational Printers
						printers.filter(
							(printer) => printer.connection_status === 'Operational' || printer.connection_status === 'Printing'
						).length === 0 && !client.withoutPrinter ? (
							<div className="text-center text-2xl">No Working Printers</div>
						) : rows.length === 0 ? (
							<div className="text-center text-2xl">No orders found</div>
						) : (
							<>
								<Chart
									chartType="Timeline"
									width="100%"
									height={50 + (printers.length + 2) * 50 + 'px'} // this is the max length to show all the printers without scroll
									data={data}
									options={options}
								/>
							</>
						)
					}
				</div>
			)}
		</div>
	);
}

export default ChartDetail;
