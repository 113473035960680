import React from 'react';
import SideBar from '../Components/SideBar';

function Home() {
	return (
		<div className="flex w-full">
			<SideBar active_page={'home'} />
			<div className="h-screen overflow-auto flex justify-center items-center bg-blue-800 flex-col flex-1">
				<div className="text-3xl text-white font-bold">Welcome to</div>
				<img src="../assets/img/LOGOSAM.png" alt="logo" className="h-64 mt-2" />
			</div>
		</div>
	);
}

export default Home;
