import React from 'react';

function Tableview({ isLoading, heading, leftButtons, rightButtons, table, err = null }) {
	return (
		<div className="flex flex-1 flex-col py-10 px-10 h-screen overflow-auto ">
			{isLoading ? (
				<div className="flex flex-1 justify-center items-center">
					<div className="text-5xl font-bold text-gray-400">Loading...</div>
				</div>
			) : (
				<>
					<div>
						{err ? <div className="text-red-500">{err}</div> : null}
						<div className="flex justify-between mr-5">
							<div className="w-1/3 flex gap-4 justify-start">{leftButtons}</div>
							<div className="w-1/3 text-3xl text-center">{heading}</div>
							<div className="w-1/3 flex gap-4 justify-end">{rightButtons}</div>
						</div>
					</div>
					<div className="mt-4">{table}</div>
				</>
			)}
		</div>
	);
}

export default Tableview;
