import React from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
	const navigate = useNavigate();
	return (
		<div className="h-screen bg-darkGrey md:flex">
			<div className="relative md:flex justify-center overflow-hidden w-100 bg-white i w-full bg-transparent">
				<div className="relative md:flex justify-center content-center flex-wrap items-center flex-start">
					<img src="../assets/img/samLogoSmall.png" alt="" className="max-w-xs" />
					<button
						onClick={() => navigate('/login')}
						type="button"
						className="block w-full bg-white mt-10 py-2 rounded-2xl text-black-700 font-semibold mb-2"
					>
						Login
					</button>
				</div>
			</div>
		</div>
	);
};

export default LandingPage;
